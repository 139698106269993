import {JsonWebToken} from "../../types/JsonWebToken.type";
import {User} from "../../types/User.type";

const LocalStorageService = (function () {
  function setToken(tokenObj: JsonWebToken): void {
    localStorage.setItem('access_token', tokenObj.access_token);
    localStorage.setItem('refresh_token', tokenObj.refresh_token);
  }

  function setUser(userObj: User): void {
    localStorage.setItem('user', JSON.stringify(userObj));
  }

  function getAccessToken(): string | null {
    return localStorage.getItem('access_token');
  }

  function isLoggedIn(): boolean {
    return localStorage.getItem('access_token') !== null;
  }

  function getUser(): User | false {
    if (!isLoggedIn()) {
      return false;
    }
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) as User : false;
  }

  function handlePasswordChange(): void {
    const user = getUser();
    if (user && typeof user === 'object') {
      user.hasPassword = true;
      setUser(user);
    }
  }

  function getRefreshToken(): string | null {
    return localStorage.getItem('refresh_token');
  }

  function clearToken(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('user');
  }

  function hasCartUuid(): boolean {
    return localStorage.getItem('cart_uuid') !== null;
  }

  function getCartUuid(): string | null {
    return localStorage.getItem('cart_uuid');
  }

  function setCartUuid(cartUuid: string): void {
    localStorage.setItem('cart_uuid', cartUuid);
  }

  function clearCartUuid(): void {
    localStorage.removeItem('cart_uuid');
  }

  return {
    clearToken,
    isLoggedIn,
    getCartUuid,
    setCartUuid,
    clearCartUuid,
    handlePasswordChange,
    setToken,
    getAccessToken,
    getRefreshToken,
    hasCartUuid,
  };
})();

export default LocalStorageService;