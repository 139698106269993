import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import {useTranslation} from 'react-i18next';
import DocumentTitle from 'components/DocumentTitle';
import EventTile from 'components/EventTile';
import Header from 'components/Header';
import Footer from 'components/Footer';
import './Events.scss';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import {RouteComponentProps} from "react-router";
import {useGetEvents} from "hooks/Events/useGetEvents";


// toast.error(<ToastContent type="error" message={t('error.events')} />);
interface EventsProps extends RouteComponentProps {
}

export const Events: React.FC<EventsProps> = ({match}) => {
    const {
        data: events,
        isLoading: isEventsLoading,
        isError: isEventsError
    } = useGetEvents();

    const {t} = useTranslation();

    if (isEventsLoading) return <FullScreenLoader route={match}/>;
    if (isEventsError) return (<div>{t('error.events')}</div>)

    if (events === undefined) {
        return <div></div>;
    }

    return (
        <div className="Events">
            <Header route={match}/>

            <div className="Main">
                <Container>
                    <DocumentTitle title="Soul Survivor | Tickets"/>

                    <Row>
                        {events && events.length > 0 ? events.map((event) => (
                            <EventTile
                                key={`event-${event.id}`}
                                name={event.name}
                                description={event.details.description}
                                slug={event.slug}
                                heroImage={event.urls.heroImage}
                                startDateTime={event.details.from}
                                endDateTime={event.details.till}
                                location={event.details.location.title}
                            />
                        )) : <div className="NoResults">{t('event.no_results')}</div>}
                    </Row>

                </Container>
            </div>

            <Footer/>
        </div>
    );
}

export default Events;
