import React, {useMemo, useState} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';
import './Form.scss';
import {generatePath} from 'react-router-dom';
import moment from 'moment';
import {LinkContainer} from 'react-router-bootstrap';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import CreateIcon from '@material-ui/icons/Create';
import FormItem from 'components/Form/FormItem';
import {isDate} from '../../util';
import ButtonSpinner from "../Loader/ButtonSpinner";
import {SoulMeals} from "../../types/SingleEvent.type";
import {SingleTicket} from "../../types/Account/SingleTicket.type";
import {Attendee} from "../../types/Attendee.type";
import {Field, Formik} from "formik";
import * as Yup from "yup";
import Dropdown from "../Input/Formik/Dropdown";
import AttendeeForm from "./AttendeeForm";
import useAssignVisitorToTicket from "../../hooks/Order/useAssignVisitorToTicket";

type PersonalizeFormProps = {
    ticket: SingleTicket,
    attendees: Attendee[],
    orderSlug: string,
    meals: SoulMeals,
}


const prepareSchema = (t) => {
    return Yup.object().shape({
        selectedAttendee: Yup.string()
            .trim()
            .required(t('form.validation.required')),
    });
};

const prepareInitialValues = (attendee?: Attendee) => {
    return {
        selectedAttendee: attendee ? attendee.token : "0",
    }
}

const PersonalizeForm: React.FC<PersonalizeFormProps> = ({
                                                             ticket,
                                                             attendees,
                                                             orderSlug,
                                                             meals,
                                                         }) => {
    const {t, i18n} = useTranslation();
    const [showForm, setShowForm] = useState<boolean>(ticket.attendee === null);
    const schema = useMemo(() => prepareSchema(t), [t])
    const initialValues = useMemo(() => prepareInitialValues(ticket.attendee), [ticket]);
    const {
        assignVisitor,
        isLoading: isVisitorLoading,
        isSaved: isVisitorAssigned
    } = useAssignVisitorToTicket(orderSlug, ticket, t, i18n);

    const attendeesOptions = attendees ? attendees.map((attendee) => ({
        label: (attendee.preposition ? `${attendee.firstName} ${attendee.preposition} ${attendee.lastName}` : `${attendee.firstName} ${attendee.lastName}`),
        value: attendee.token,
    })) : [];

    attendeesOptions.unshift({label: t('personalize.new'), value: '0'});

    const handleSubmit = async (values, {setSubmitting}) => {
        await assignVisitor(values.selectedAttendee);
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >
            {({handleChange, handleBlur, values, setFieldValue, isSubmitting, handleSubmit}) => {
                const age = isDate(values.dateOfBirth) ? moment().diff(moment(values.dateOfBirth, 'DD-MM-YYYY'), 'years') : 18;

                const selectedAttendee = attendees.find((attendee) => attendee.token === values.selectedAttendee);

                return <div
                    id="PersonalizeTicketForm"
                >
                    {attendeesOptions.length > 1 && (
                        <div>
                            <FormItem label={t('form.label.link_to')}>
                                <div className="AttendeesDropdown">
                                    <Field
                                        component={Dropdown}
                                        options={attendeesOptions}
                                        placeholder={t('form.label.link_to')}
                                        name="selectedAttendee"
                                        onChange={(e) => {
                                            setShowForm(false);
                                        }}
                                    />
                                    {!showForm
                                        && (
                                            <div>
                                                <Button className="EditDetailsButton" variant="link"
                                                        onClick={() => setShowForm(true)}>
                                                    <CreateIcon/>
                                                    {' '}
                                                    {t('button.edit_details_of')}
                                                    {' '}
                                                    {selectedAttendee && selectedAttendee.firstName}
                                                </Button>
                                            </div>
                                        )}
                                </div>
                            </FormItem>
                        </div>
                    )}
                    {showForm ? <AttendeeForm
                        attendee={ticket.attendee}
                        ticket={ticket}
                        orderSlug={orderSlug}
                        meals={meals}
                    /> : <Row className="Buttons">
                        <Col md={6}>
                            <LinkContainer
                                to={`/${i18n.language}${generatePath(t('routes:ticket.account_orders_order'),
                                    {orderSlug})}`}
                            >
                                <Button className="BackLink BackLinkBottom" variant="link">
                                    <ChevronLeftIcon/>
                                    {' '}
                                    {t('button.back_to_tickets')}
                                </Button>
                            </LinkContainer>
                        </Col>
                        <Col md={6}>
                            <div className="ButtonRight">
                                <Button variant="primary" disabled={isSubmitting} onClick={() => handleSubmit()}
                                        type="submit">
                                    {t('button.save')}
                                    <ButtonSpinner active={isSubmitting}/>
                                </Button>
                            </div>
                        </Col>
                    </Row>}
                </div>;
            }}
        </Formik>
    );
}

export default PersonalizeForm;