import React from 'react';
import Header from "../../components/Header";
import DocumentTitle from "../../components/DocumentTitle";
import Footer from "../../components/Footer";
import {Col, Container, Row} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import Button from "react-bootstrap/Button";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

export const PageNotFound = () => {
    const { t } = useTranslation();

    return <div className="Error">
        <Header />

        <div className="Main">
            <Container>
                <Row>
                    <Col md={{ span: 6, offset: 3 }}>
                        <DocumentTitle title="Soul Survivor | Tickets"/>

                        <h2>{t('error_pages.not_found.title')}</h2>
                        <p>
                            {t('error_pages.not_found.text')}
                        </p>

                        <Button variant="link" href="javascript:history.go(-1)">
                            <ChevronLeftIcon />
                            {t('error_pages.not_found.button')}
                        </Button>
                </Col>
            </Row>

            </Container>
        </div>

        <Footer/>
    </div>;
}

export default PageNotFound;