import React from 'react';
import Form from 'react-bootstrap/Form';
import MaskedInput from 'react-text-mask';

import '../Input.scss';
import {FieldProps} from "formik";
import FieldErrors from "./FieldErrors";

type InputProps = FieldProps & React.HTMLProps<HTMLInputElement> & {
    mask: MaskedInput.mask,
    serverError?: string[],
    type?: string,
    passRef: React.Ref<any>,
}

const Input: React.FC<InputProps> = ({
                                         field,
                                         form,
                                         meta,
                                         mask,
                                         placeholder,
                                         serverError,
                                         rows,
                                         autoComplete,
                                         autoFocus,
                                         type,
                                        disabled,
                                        readOnly,
                                        passRef
                                     }) => {

    if (field === undefined) {
        return null;
    }

    return (
        <Form.Group className="Input">
            {mask ? (
                <MaskedInput
                    mask={mask}
                    className="form-control"
                    placeholder={placeholder}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    rows={rows}
                    type={type}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    disabled={disabled}
                    readOnly={readOnly}
                    ref={passRef}
                />
            ) : (
                <Form.Control
                    placeholder={placeholder}
                    name={field.name}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    value={field.value}
                    rows={rows}
                    type={type}
                    autoComplete={autoComplete}
                    autoFocus={autoFocus}
                    as={rows ? 'textarea' : undefined}
                    disabled={disabled}
                    readOnly={readOnly}
                    ref={passRef}
                />
            )}

            <FieldErrors field={field} serverErrors={serverError} />
        </Form.Group>
    );
}

export default Input;