import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {handleInternalApiCall, METHOD_POST, METHOD_PUT} from "../../services/api-requests/ApiCallHandler";
import ToastContent from 'components/ToastContent';
import moment from "moment";
import {logError} from 'services/error-handling/ErrorHandler';
import {FormikValues} from "formik";
import {Attendee} from "../../types/Attendee.type";

export const useVisitorSubmit = (orderSlug, t) => {
    const [validation, setValidation] = useState({});
    const [isSaved, setIsSaved] = useState(false);
    const [savedVisitor, setSavedVisitor] = useState(null);

    const saveVisitor = async (attendee: Attendee | null, values: FormikValues, { setSubmitting }, onSuccess: (visitorToken: string) => void) => {
        setSubmitting(true);

        const transformedValues = {
            ...values,
            dateOfBirth: moment(values.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD'),
        };

        try {
            const response = await handleInternalApiCall(
                `order/${orderSlug}/visitor${attendee !== null ? `/${attendee.token}` : ''}`,
                (attendee !== null ? METHOD_PUT : METHOD_POST),
                true,
                transformedValues
            );

            setValidation({});
            setIsSaved(true);
            setSavedVisitor(response.data);

            if (onSuccess) {
                onSuccess(response.data.token);
            }
        } catch (error) {
            if (error.response?.status === 400 && error.response.data.fields) {
                setValidation(error.response.data.fields);
            } else if (error.response?.status === 400 && error.response.data.message) {
                setValidation({});
                toast.error(<ToastContent type="error" message={error.response.data.message} />);
            } else {
                logError(error);
                setValidation({});
                toast.error(<ToastContent type="error" message={t('error.personalize.post')} />);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return {
        saveVisitor,
        validation,
        isSaved,
        savedVisitor,
    };
};

export default useVisitorSubmit;