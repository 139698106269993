import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {handleInternalApiCall, METHOD_POST} from "../../services/api-requests/ApiCallHandler";
import ToastContent from 'components/ToastContent';
import moment from "moment";
import {logError} from 'services/error-handling/ErrorHandler';
import {FormikValues} from "formik";

export const useJobApplicationSubmit = (eventToken, t) => {
    const [validation, setValidation] = useState({});
    const [isSaved, setIsSaved] = useState(false);

    const submit = async (values: FormikValues, { setSubmitting }) => {
        setSubmitting(true);

        const transformedValues = {
            ...values,
            dateOfBirth: moment(values.dateOfBirth, 'DD-MM-YYYY').format('YYYY-MM-DD'),
            dietaryRestrictions: values.hasAllergies === "1" ? values.dietaryRestrictions.filter((item) => item !== 'other') : [],
            customDietaryRestriction: values.hasAllergies === "1" ? (values.dietaryRestrictions.includes('other') ? values.customDietaryRestriction : null) : null,
            dietaryPreference: values.hasDiet === "1" ? values.dietaryPreference.filter((item) => item !== 'other') : [],
            customDietaryPreference: values.hasDiet === "1" ? (values.dietaryPreference.includes('other') ? values.customDietaryPreference : null) : null,
            firstPreferredWorkgroup: values.firstPreferredWorkgroup === '0' ? null : values.firstPreferredWorkgroup,
            secondPreferredWorkgroup: values.secondPreferredWorkgroup === '0' ? null : values.secondPreferredWorkgroup,
            thirdPreferredWorkgroup: values.thirdPreferredWorkgroup === '0' ? null : values.thirdPreferredWorkgroup,
            lastYearActiveAsEmployee: parseInt(values.lastYearActiveAsEmployee, 10),
        };

        try {
            await handleInternalApiCall(
                `event/${eventToken}/job-application/create`,
                METHOD_POST, // Replace with METHOD_POST if constant
                false,
                transformedValues
            );

            setValidation({});
            setIsSaved(true);
        } catch (error) {
            if (error.response?.status === 400 && error.response.data.fields) {
                setValidation(error.response.data.fields);
            } else if (error.response?.status === 400 && error.response.data.message) {
                setValidation({});
                toast.error(<ToastContent type="error" message={error.response.data.message} />);
            } else {
                logError(error);
                setValidation({});
                toast.error(<ToastContent type="error" message={t('error.volunteer.post')} />);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return {
        submit,
        validation,
        isSaved,
    };
};

export default useJobApplicationSubmit;