import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {handleInternalApiCall, METHOD_POST,} from "../../services/api-requests/ApiCallHandler";
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {SingleTicket} from "../../types/Account/SingleTicket.type";
import {generatePath} from "react-router";

export const useAssignVisitorToTicket = (orderSlug: string, ticket: SingleTicket, t, i18n) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const assignVisitor = async (attendeeToken: string) => {
        setIsLoading(true);

        try {
            await handleInternalApiCall(
                `ticket/${ticket.token}/assign`,
                METHOD_POST,
                true,
                {
                    visitor_token: attendeeToken,
                }
            );

            setIsLoading(false);
            setIsSaved(true);

            window.location.href = `/${i18n.language}${generatePath(t('routes:ticket.account_orders_order'), { orderSlug })}`;
        } catch (error) {
            if (error.response?.status === 400 && error.response.data.message) {
                toast.error(<ToastContent type="error" message={error.response.data.message} />);
            } else {
                logError(error);
                toast.error(<ToastContent type="error" message={t('error.personalize.post')} />);
            }

            setIsLoading(false);
        }
    };

    return {
        assignVisitor,
        isLoading,
        isSaved,
    };
};

export default useAssignVisitorToTicket;