import React from 'react';
import Form from 'react-bootstrap/Form';
import MaskedInput from 'react-text-mask';

import '../Input.scss';
import {FieldProps} from "formik";
import FieldErrors from "./FieldErrors";

type OptionType = { label: string; value: string };

type RadioGroupProps = FieldProps & React.HTMLProps<HTMLInputElement> & {
    mask: MaskedInput.mask,
    serverError?: string[],
    type?: string,
    variant?: 'horizontal' | 'vertical',
    options: OptionType[],
}

type RadioOptionProps = OptionType & React.HTMLProps<HTMLInputElement>

const RadioGroup: React.FC<RadioGroupProps> = ({
                                                   field,
                                                   form,
                                                   meta,
                                                   variant,
                                                   serverError,
                                                   options
                                               }) => {

    if (field === undefined) {
        return null;
    }

    return (
        <Form.Group className={`RadioGroup${variant === 'horizontal' ? ' Horizontal' : ' Vertical'}`}>
            {options.map((option) => {
                return (
                <RadioOption
                    key={option.value}
                    label={option.label}
                    checked={field.value === option.value}
                    value={option.value}
                    onChange={field.onChange}
                    name={field.name}
                />
            )})}
            <FieldErrors field={field} serverErrors={serverError}/>
        </Form.Group>
    );
}

const RadioOption: React.FC<RadioOptionProps> = ({
                         label, checked, onChange, name, value,
                     }) => {
    return (
        <label className="RadioOption">
            <input
                type="radio"
                onChange={onChange}
                value={value}
                checked={checked}
                name={name}
            />
            <div className="icon"/>
            <span>{label}</span>
        </label>
    );
}

export default RadioGroup;