import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {useTranslation} from 'react-i18next';
import SubArrowIcon from '@material-ui/icons/SubdirectoryArrowRight';
import MoneyString from 'components/MoneyString/index';
import VoucherRow from 'components/Cart/VoucherRow';
import CartRemoveMessage from './CartRemoveMessage';
import {CartItem} from "../../types/CartItem.type";
import {useGetAmount} from "../../hooks/Cart/useGetAmount";
import {useChangeCartItem} from "../../hooks/Cart/useChangeCartItem";
import {debounce} from 'lodash';

type LargeCartItemProps = {
    eventToken: string,
} & CartItem;

const LargeCartItem: React.FC<LargeCartItemProps> = ({
                                                         token,
                                                         name,
                                                         varietyName,
                                                         quantity,
                                                         originalPrice,
                                                         unitPrice,
                                                         discounts,
                                                         vouchers,
                                                         eventToken,
                                                         voucher
                                                     }) => {

    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

    const {t} = useTranslation();

    const initialAmount = useGetAmount({eventToken, token});
    const [amount, setAmount] = useState(initialAmount);
    const [amountChanged, setAmountChanged] = useState(false);

    const {changeAmount, removeItem} = useChangeCartItem({
        eventToken,
        errorMessage: t('error.cart.edit')
    });

    const debouncedChangeAmount = debounce((newAmount: number) => {
        if (newAmount !== initialAmount) {
            changeAmount(token, newAmount);
        }
    }, 500);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newAmount = parseInt(e.target.value, 10);
        if (!isNaN(newAmount)) {
            setAmount(newAmount);
            debouncedChangeAmount(newAmount);
        }
    };

    const handleRemove = () => {
        removeItem(token, !!voucher);
    }

    const hasDiscount = originalPrice.amount !== unitPrice.amount;
    let discountTypes = [];

    if (hasDiscount) {
        discountTypes = discounts !== null ? discounts.filter((discount) => discount.active) : [];
    }

    const amountDiscount = vouchers ? vouchers.length : 0;
    const amountNoDiscount = quantity - amountDiscount;

    const priceNoDiscount = {
        amount: parseFloat(unitPrice.amount) * quantity,
        currency: unitPrice.currency,
    };

    return (
        <div className="CartItem">
            <Row className="CartItemDesktop">
                <Col className="CartItemName" xs={6}>
                    <div className="CartItemNameTicket">
                        {name}
                        {discountTypes.length > 0
                            && discountTypes.map((discount, index) => (
                                <div
                                    className="DiscountBadge"
                                    id={`discount-${index}`}
                                    key={discount.type}
                                >
                                    {t(`cart.discountTypes.${discount.type.toLowerCase()}`)}
                                </div>
                            ))}
                    </div>
                    {varietyName !== null ? <div className="CartItemNameVariety">{varietyName}</div> : ''}

                    {vouchers && vouchers.length > 0
                        && (
                            <div className="VoucherInformation">
                                {vouchers.map((voucher) => (
                                    <VoucherRow
                                        key={voucher.code}
                                        code={voucher.code}
                                        name={voucher.name}
                                        amount={voucher.amount}
                                        eventToken={eventToken}
                                        discountType={voucher.discountType}
                                        percent={voucher.percent}
                                    />
                                ))}
                                {amountNoDiscount > 0 && (
                                    <div className="VoucherRow OtherTicketRow">
                                        <SubArrowIcon/>
                                        {amountNoDiscount}
                                        x
                                        {' '}
                                        {name}
                                        {' '}
                                        à
                                        {' '}
                                        <span className="VoucherPrice">
                      <MoneyString amount={unitPrice.amount} currency={unitPrice.currency}/>
                    </span>
                                    </div>
                                )}
                            </div>
                        )}

                </Col>
                <Col className="CartPriceAmount" xs={5}>
                    <Row>
                        <Col className="CartItemPricePiece" xs={3}/>
                        <Col className="CartItemPricePiece" xs={3}>
                            <MoneyString amount={unitPrice.amount} currency={unitPrice.currency}/>
                        </Col>
                        <Col className="CartItemQuantity" xs={3}>
                            <Form.Control
                                className="TicketAmount"
                                type="text"
                                value={amount}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col className="CartItemPriceTotal" xs={3}>
                            <MoneyString amount={priceNoDiscount.amount} currency={priceNoDiscount.currency}/>
                        </Col>
                    </Row>
                </Col>
                <Col className="CartItemActions" xs={1}>
                    {showRemoveConfirmation
                        && (
                            <CartRemoveMessage
                                quantity={amount}
                                cancel={() => setShowRemoveConfirmation(false)}
                                confirm={handleRemove}
                                voucher={false}
                            />
                        )}
                    <Button
                        variant="link"
                        className="RemoveButton"
                        onClick={() => setShowRemoveConfirmation(true)}
                    >
                        <DeleteIcon className="RemoveButtonIcon"/>
                    </Button>
                </Col>
            </Row>
            <Row className="CartItemMobile">
                <Row>
                    <Col className="CartTableLabel" xs={4}>
                        {t('cart.product')}
                    </Col>
                    <Col className="CartItemName" xs={8}>
                        <div className="CartItemNameTicket">{name}</div>
                        {varietyName !== null ? <div className="CartItemNameVariety">{varietyName}</div> : ''}

                        {discountTypes.length > 0
                            && discountTypes.map((discount, index) => (
                                <div
                                    className="DiscountBadge"
                                    id={`discount-${index}`}
                                    key={discount.type}
                                >
                                    {t(`cart.discountTypes.${discount.type.toLowerCase()}`)}
                                </div>
                            ))}

                        {vouchers && vouchers.length > 0
                            && (
                                <div className="VoucherInformation">
                                    {vouchers.map((voucher) => (
                                        <VoucherRow
                                            key={voucher.code}
                                            eventToken={eventToken}
                                            code={voucher.code}
                                            name={voucher.name}
                                            amount={voucher.amount}
                                            discountType={voucher.discountType}
                                            percent={voucher.percent}
                                        />
                                    ))}
                                    {amountNoDiscount > 0 && (
                                        <div className="VoucherRow OtherTicketRow">
                                            <SubArrowIcon/>
                                            {amountNoDiscount}
                                            x
                                            {' '}
                                            {name}
                                            {' '}
                                            à
                                            {' '}
                                            <span className="VoucherPrice">
                        <MoneyString
                            amount={unitPrice.amount}
                            currency={unitPrice.currency}
                        />
                      </span>
                                        </div>
                                    )}
                                </div>
                            )}
                    </Col>
                </Row>
                <Row>
                    <Col className="CartTableLabel" xs={4}>
                        {t('cart.pricePiece')}
                    </Col>
                    <Col className="CartItemPricePiece" xs={8}>
                        <MoneyString amount={unitPrice.amount} currency={unitPrice.currency}/>
                    </Col>
                </Row>
                <Row>
                    <Col className="CartTableLabel" xs={4}>
                        {t('cart.quantity')}
                    </Col>
                    <Col className="CartItemQuantity" xs={6}>
                        <Form.Control
                            className="TicketAmount"
                            type="text"
                            value={amount}
                            onChange={handleChange}
                        />
                    </Col>
                    <Col className="CartItemActions" xs={2}>
                        {showRemoveConfirmation
                            && (
                                <CartRemoveMessage
                                    quantity={amount}
                                    cancel={() => setShowRemoveConfirmation(false)}
                                    confirm={handleRemove}
                                    voucher={false}
                                />
                            )}
                        <Button
                            variant="link"
                            className="RemoveButton"
                            onClick={() => setShowRemoveConfirmation(true)}
                        >
                            <DeleteIcon className="RemoveButtonIcon"/>
                        </Button>
                    </Col>
                </Row>
                <Row className="CartPriceAmount">
                    <Col className="CartTableLabel" xs={4}>
                        {t('cart.subtotal')}
                    </Col>
                    <Col className="CartItemPriceTotal" xs={8}>
                        <MoneyString amount={priceNoDiscount.amount} currency={priceNoDiscount.currency}/>
                    </Col>
                </Row>
            </Row>
        </div>
    );
}

export default LargeCartItem;