import LocalStorageService from 'services/storage/LocalStorageService';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleApiCall, METHOD_POST} from 'services/api-requests/ApiCallHandler';
import i18n from '../../i18n';
import {Credentials} from "../../types/Credentials.type";
import {useState} from "react";

export const useLoginByPassword = (t) => {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const login = async ({username, password}: Credentials) => {
        LocalStorageService.clearToken();
        setIsLoading(true);

        try {
            const response = await handleApiCall('/login', METHOD_POST, false, {username, password});

            LocalStorageService.setToken({
                access_token: response.data.token,
                refresh_token: response.data.refreshToken,
            });

            setIsLoading(false);
            setError(false);

            window.location.href = `/${i18n.language}`;
        } catch (error) {
            logError(error);

            setError(true);
            setIsLoading(false);
        }
    }

    return {
        login,
        error,
        isLoading
    };
};
