import React from 'react';
import {Col, Row} from 'react-bootstrap';
import Checkbox, {CheckboxProps} from "./Checkbox";
import {FieldProps} from "formik";
import {toggleElement} from '../../../util';
import FieldErrors from "./FieldErrors";

type CheckboxGroupProps = FieldProps & React.HTMLProps<HTMLInputElement> & {
    label: string;
    columns?: number;
    options: CheckboxProps[];
    serverError?: string[];
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ field, form, meta, label, columns = 1, options, serverError }) => {
    const createColumns = () => {
        const returnColumns: React.ReactNode[] = [];
        const amountPerColumn = Math.ceil(options.length / columns);
        const columnSize = Math.ceil(12 / columns);

        for (let columnNumber = 0; columnNumber < columns; columnNumber += 1) {
            const start = columnNumber * amountPerColumn;
            const optionsInColumn = options.slice(start, start + amountPerColumn);

            const columnContent = optionsInColumn.map((checkbox, index) => (
                <Checkbox
                    key={`${label}-${columnNumber}-${index}`}
                    label={checkbox.label}
                    name={checkbox.name}
                    onChange={() => {
                        let value = toggleElement(field.value, checkbox.value);
                        form.setFieldValue(field.name, value);
                    }}
                    onBlur={checkbox.onBlur}
                    value={field.value.includes(checkbox.value)}
                    inGroup
                    field={field}
                    form={form}
                    meta={meta}
                />
            ));

            returnColumns.push(
                <Col key={`${label}-${columnNumber}`} md={columnSize}>
                    {columnContent}
                </Col>
            );
        }

        return returnColumns;
    };

    return (
        <div className="CheckboxGroupWrapper">
            <Row className="CheckboxGroupRow">{createColumns()}</Row>

            <FieldErrors field={field} serverErrors={serverError} />
        </div>
    );
};

export default CheckboxGroup;