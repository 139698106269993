import React, {useEffect} from 'react';
import {Redirect, Route} from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';
import {createBrowserHistory} from 'history';
import {trackPageView} from 'services/tracking/TrackingHandler';
import i18n from '../../i18n';

type LocalizedRouterProps = {
  children: React.ReactNode;
  RouterComponent: React.ComponentType<{ history: typeof history, children: React.ReactNode }>;
};

const history = createBrowserHistory();

history.listen(() => {
  trackPageView(window.location.href);
});

const LocalizedRouter: React.FC<LocalizedRouterProps> = ({ children, RouterComponent }) => {
  useEffect(() => {
    trackPageView(window.location.href);
  }, []);

  return (
      <RouterComponent history={history}>
        <Route path="/:lang([a-z]{2})">
          {({ match, location }: { match: { params: { lang?: string } } | null; location: { pathname: string } }) => {
            const params = match ? match.params : {};
            const { lang = 'nl' } = params;

            const { pathname } = location;

            if (!pathname.includes(`/${lang}/`)) {
              return <Redirect to={`/${lang}/`} />;
            }

            return (
                <I18nextProvider i18n={i18n}>
                  {children}
                </I18nextProvider>
            );
          }}
        </Route>
      </RouterComponent>
  );
};

export default LocalizedRouter;
