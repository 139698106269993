import React from 'react';
import Form from 'react-bootstrap/Form';
import '../Checkbox.scss';
import '../Input.scss';
import {FieldProps} from "formik";
import FieldErrors from "./FieldErrors";

export type CheckboxProps = FieldProps & {
    label: string;
    name: string;
    onChange: (e: { target: { name: string; value: boolean } }) => void;
    onBlur?: (e: { target: { name: string; value: boolean } }) => void;
    value: boolean | string | number;
    serverError?: string[];
    required?: boolean;
    inGroup?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
                                               label,
                                               name,
                                               onChange,
                                               value = false,
                                               serverError,
                                               required,
                                               form,
                                               field,
                                               inGroup = false,
                                           }) => {
    const parsedValue = inGroup ? !!value : !!+field.value;

    const handleChange = () => {
        if (inGroup) {
            onChange({target: {name, value: !parsedValue}});
        } else {
            form.setFieldValue(field.name, parsedValue ? '0' : '1');
        }
    };

    return (
        <Form.Group className="CheckboxGroup">
            <label className="Checkbox">
                <input
                    type="checkbox"
                    checked={parsedValue}
                    required={required}
                    name={name}
                    onChange={handleChange}
                />
                <div className="icon"/>
                <span dangerouslySetInnerHTML={{__html: label}}/>
            </label>

            {!inGroup && <FieldErrors field={field} serverErrors={serverError} />}
        </Form.Group>
    );
};

export default Checkbox;