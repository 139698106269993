import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useTranslation} from 'react-i18next';
import EventHeader from 'components/EventHeader';
import DocumentTitle from 'components/DocumentTitle';
import './Volunteer.scss';
import VolunteerForm from 'components/Form/VolunteerForm';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import {GetEventQueryParams, useGetEvent} from "../../../hooks/SingleEvent/useGetEvent";
import {RouteComponentProps} from "react-router";
import PageNotFound from "../../Error/PageNotFound";

interface VolunteerProps extends RouteComponentProps<{
    eventSlug: string;
}> {
}

const Volunteer: React.FC<VolunteerProps> = ({match}) => {
    const {t} = useTranslation();
    const {eventSlug} = match.params;

    const {
        data: event,
        isLoading: isEventLoading,
        isError: isEventError
    } = useGetEvent({eventSlug} as GetEventQueryParams);

    if (isEventLoading) return <FullScreenLoader route={match}/>;
    if (event === null) return <PageNotFound/>;
    if (isEventError) return <div>{t('error.event')}</div>;

    if (event === undefined) {
        return <div></div>;
    }

    return (
        <div className="Volunteer">
            <DocumentTitle
                title={`Soul Survivor ${event.name} | ${t('volunteer.page_title')}`}
            />

            <Header route={match}/>

            <div className="Main">
                <EventHeader
                    name={event.name}
                    description={event.details.description}
                    fromDate={event.details.from}
                    tillDate={event.details.till}
                    venue={event.details.location.title}
                    small
                    websiteUrl={event.urls.website}
                    mapsUrl={event.urls.maps}
                />

                <Container className="VolunteerContainer">
                    <Row>
                        <Col lg={7} className="VolunteerColumn CheckOutColumn">
                            <div className="DetailsBlock Block">
                                <h2>{t('volunteer.title')}</h2>

                                <VolunteerForm
                                    eventSlug={event.slug}
                                    eventToken={event.id}
                                    meals={event.soulMeals}
                                />
                            </div>
                        </Col>
                        <Col lg={5} className="CheckOutColumn CheckOutInfo">
                            <p>
                                <strong>
                                    {t('order.event.overview.sidebar.detailsheader')}
                                </strong>
                                <br/>
                                {t('order.event.overview.sidebar.details_volunteer')}
                            </p>

                            <Button
                                href={t('termsAndPrivacy.privacyStatement.url')}
                                variant="link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('order.event.overview.sidebar.privacy')}
                                {' '}
                                <ChevronRightIcon/>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Footer/>
        </div>
    );
}

export default Volunteer;