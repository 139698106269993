import React, {useCallback, useState} from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {StickyContainer} from 'react-sticky';
import {useTranslation} from 'react-i18next';
import EventHeader from 'components/EventHeader';
import EventTickets from 'components/EventTickets';
import Cart from 'components/Cart';
import CartMobile from 'components/Cart/CartMobile';
import DocumentTitle from 'components/DocumentTitle';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import Alert from 'components/Alert';
import PageNotFound from "../../Error/PageNotFound";
import {GetEventTicketsQueryParams, useGetEventTickets} from "../../../hooks/SingleEvent/useGetEventTickets";
import {generatePath, Redirect, RouteComponentProps} from "react-router";
import {trackMarketingCampaign} from "../../../services/tracking/TrackingHandler";
import ErrorPage from "../../Error/ErrorPage";

interface EventProps extends RouteComponentProps<{
  eventSlug: string;
}> {
}

export const SingleEvent: React.FC<EventProps> = ({match}) => {
    const [cartAmount, setCartAmount] = useState<Record<string, number>>({});
    const [shouldUpdateCart, setShouldUpdateCart] = useState(false);
    const [isEmployee, setIsEmployee] = useState(false);

    // Function to trigger cart update
    const triggerUpdate = useCallback(() => {
        setShouldUpdateCart(true);
    }, []);

    const updateCart = useCallback((cartItems: any[], employee: boolean) => {
        const amount: Record<string, number> = {};
        cartItems.forEach((item) => {
            amount[item.token] = item.quantity;
        });

        setCartAmount(amount);
        setShouldUpdateCart(false);
        setIsEmployee(employee);
    }, []);

    const { t, i18n } = useTranslation();

    const {eventSlug} = match.params;

    const {
      data: data,
      isLoading: isEventLoading,
      isError: isEventError
    } = useGetEventTickets({eventSlug} as GetEventTicketsQueryParams);

    if (isEventLoading) return <FullScreenLoader route={match} />;
    if (isEventError) return <ErrorPage title={t('error.title')} text={t('error.event')} />;
    if (data === null) return <PageNotFound />;

    if (data === undefined) {
        return <div></div>;
    }

    const { event, tickets } = data;

    trackMarketingCampaign(event.id)

    if (isEmployee === true) {
      return (
        <Redirect
          to={`/${i18n.language}${generatePath(t('routes:ticket.checkout'),
            { eventSlug: event.slug })}`}
        />
      );
    }

    return (
      <div className="Event">
        <Header route={match} />

        <DocumentTitle title={`Soul Survivor ${event.name} | Tickets`} />

        <div className="Main">
          <StickyContainer>
            <EventHeader
              name={event.name}
              description={event.details.description}
              fromDate={event.details.from}
              tillDate={event.details.till}
              venue={event.details.location.title}
              small={false}
              websiteUrl={event.urls.website}
              mapsUrl={event.urls.maps}
            >
              <Cart
                eventSlug={event.slug}
                eventToken={event.id}
                image={event.urls.heroImage}
              />
            </EventHeader>

            <Container>
              <Row>
                <Col className="EventTicketsWrapper" lg={7}>
                  <Row>
                  {event.shop.acceptingYouthLeader && <Col>
                    <Alert type="info" style={{ marginTop: 15 }}>
                    <strong>
                      {t('event.alert_youthleader.heading')}
                      {' '}
                    </strong>
                    {t('event.alert_youthleader.content')}
                  </Alert>
                  </Col>
                  }
                  </Row>

                  <EventTickets
                    eventSlug={event.slug}
                    eventToken={event.id}
                    tickets={tickets}
                    cartAmount={cartAmount}
                    trigger={triggerUpdate}
                  />
                </Col>
              </Row>
            </Container>

            <CartMobile
              eventSlug={event.slug}
              eventToken={event.id}
              image={event.urls.heroImage}
            />
          </StickyContainer>
        </div>

        <Footer />
      </div>
    );
  }

export default SingleEvent;