import React from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';
import './Form.scss';
import FormItem from 'components/Form/FormItem';
import {getRelativeUrl} from '../../util';
import {Field, Form, Formik, FormikHelpers, FormikValues} from "formik";
import * as Yup from "yup";
import {useLoginByPassword} from "../../hooks/Session/useLoginByPassword";
import queryString from "query-string";
import Input from "../Input/Formik/Input";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import {useGetLoginMethod} from "../../hooks/Session/useGetLoginMethod";
import {useSendLoginEmail} from "../../hooks/Session/useSendLoginEmail";
import Alert from 'components/Alert';
import ButtonSpinner from "../Loader/ButtonSpinner";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";

type InitialFormState = {
    username: string,
    password: string,
    requiresPassword: boolean,
}

const schema = Yup.object().shape({
    username: Yup.string().email('Vul een geldig e-mailadres in').required('Vul een e-mailadres in'),
    requiresPassword: Yup.boolean(),
    password: Yup.string().when('requiresPassword', {
        is: true,
        then: () => Yup.string().required('Vul een wachtwoord in'),
        otherwise: () => Yup.string()
    }),
});

const LoginForm: React.FC = () => {
    const {t, i18n} = useTranslation();

    const urlParams = queryString.parse(window.location.search);
    const redirectUrl = typeof urlParams.path !== 'undefined' ? `${window.location.origin}/${getRelativeUrl(urlParams.path)}` : `${window.location.origin}/${i18n.language}`;

    const {getMethod, error: methodError, isLoading: isLoadingMethod, methods} = useGetLoginMethod();
    const {login, error: passwordError, isLoading: isLoggingIn} = useLoginByPassword(t);
    const {sendEmail, error: emailError, isLoading: isSendingEmail, isSent: emailSent} = useSendLoginEmail(redirectUrl);

    const checkedMethod = methods !== null;
    const isLoading = isLoadingMethod || isLoggingIn || isSendingEmail;
    const error = methodError || passwordError || emailError;
    const ref = React.createRef<HTMLInputElement>();

    const handleSubmit = (values: FormikValues, formikHelpers: FormikHelpers<InitialFormState>) => {
        if (checkedMethod && values.requiresPassword === true) {
            login({username: values.username, password: values.password});
        } else {
            getMethod(values.username, (requiresPassword) => {
                formikHelpers.setFieldValue('requiresPassword', requiresPassword);

                if(!requiresPassword) {
                    sendEmail(values.username);
                }
            });
        }
    };

    let sessionError: string;

    if (typeof urlParams.e !== 'undefined' && emailSent === false) {
        if (urlParams.e === 'session_expired') {
            sessionError = t('account.session_expired');
        } else if (urlParams.e === 'key_expired') {
            sessionError = t('account.key_expired');
        } else if (urlParams.e === 'key_invalid') {
            sessionError = t('account.key_invalid');
        }
    }

    if (emailSent) {
        return <div>
            <h2>{t('login.sent.title')}</h2>
            <div className="BlockExplanation">
                {t('login.sent.subtitle')}
            </div>
        </div>;
    }

    return <Formik
        initialValues={{
            username: '',
            password: '',
            requiresPassword: '',
        }}
        validationSchema={schema}
        onSubmit={handleSubmit}
        validateOnBlur={false}
        validateOnChange={false}
    >
        {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue
              /* and other goodies */
          }) => {
            return <Form>
                <h2>{t('login.page.title')}</h2>
                <div className="BlockExplanation">
                    {t('login.page.subtitle')}
                </div>

                {(error || sessionError) && <div>
                    {error && <Alert color="danger" inline>{t('error.login.general')}</Alert>}
                    {sessionError && <Alert color="danger" inline>{sessionError}</Alert>}
                </div>}
                <FormItem label={t('form.label.email')}>
                    <Col md={12}>
                        <Field
                            component={Input}
                            placeholder={t('form.label.email')}
                            name="username"
                            autoFocus={values.requiresPassword === ''}
                            readOnly={values.requiresPassword}
                            type="email"
                            passRef={ref}
                        />
                    </Col>
                </FormItem>

                {values.requiresPassword ? (
                    <div>
                        <FormItem label={t('form.label.password')}>
                            <Col md={12}>
                                <Field
                                    component={Input}
                                    placeholder={t('form.label.password')}
                                    name="password"
                                    type="password"
                                    autoFocus={values.requiresPassword === true}
                                />
                            </Col>
                        </FormItem>

                        <Row className="Buttons">
                            <Col xs={4}>
                                <Button className="BackLink BackLinkBottom" variant="link" onClick={() => {
                                    setFieldValue('requiresPassword', '');
                                    ref.current.focus();
                                }}>
                                    <ChevronLeftIcon />
                                    {' '}
                                    {t('button.back')}
                                </Button>
                            </Col>
                            <Col xs={8}>
                                <div className="ButtonRight">
                                    <Button variant="link" onClick={() => {
                                        sendEmail(values.username);
                                    }}>
                                        <MailOutlinedIcon/>
                                        {' '}
                                        {t('button.send_login_email')}
                                    </Button>
                                    <Button variant="primary" disabled={isLoading} type="submit">
                                        {t('button.login')}
                                        <ButtonSpinner active={isLoading}/>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                ) : (
                    <div>
                        <Row className="Buttons">
                            <Col md={6}/>
                            <Col md={6}>
                                <div className="ButtonRight">
                                    <Button disabled={isLoading} type="submit">
                                        {t('button.continue')}
                                        <ButtonSpinner active={isLoading}/>
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    )
                }
            </Form>
        }}
    </Formik>;

}

export default LoginForm;