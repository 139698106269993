import React from 'react';
import EventTicketVariety from 'components/EventTickets/EventTicketVariety';
import {TicketVariety} from "../../types/SingleTicket.type";


interface EventTicketProps {
  token: string;
  name: string;
  varieties: TicketVariety[];
  eventSlug: string;
  eventToken: string;
}

const EventTicket: React.FC<EventTicketProps> = ({
                                                   token, name, varieties, eventSlug, eventToken,
                                                 }) => (
    <div className="EventTicket" id={token}>
      <h2 className="EventTicketName">{name}</h2>

      {varieties && varieties.length > 0 ? varieties.map((variety) => {
        return (
            <EventTicketVariety
                key={variety.token}
                eventToken={eventToken}
                token={variety.token}
                name={variety.name}
                description={variety.description}
                priceIncludingVat={variety.priceIncludingVat}
                discountedPriceIncludingVat={variety.discountedPriceIncludingVat}
                discounts={variety.discounts}
                openByDefault={variety.openByDefault}
            />
        );
      }) : null}
    </div>
);

export default EventTicket;