import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useTranslation} from 'react-i18next';
import {LinkContainer} from 'react-router-bootstrap';
import DocumentTitle from 'components/DocumentTitle';
import Breadcrumbs from 'components/Breadcrumbs';
import SmallCart from 'components/Cart/SmallCart';
import './Details.scss';
import DetailsForm from 'components/Form/DetailsForm';
import CheckoutFooter from 'components/CheckoutFooter';
import {GetCartQueryParams, useGetCart} from "../../../hooks/Cart/useGetCart";
import {SingleEvent} from "../../../types/SingleEvent.type";

type DetailsProps = {
  event: SingleEvent,
}

const Details: React.FC<DetailsProps> = ({event}) => {
  const eventToken = event.id;

  const {
    data: data,
    isLoading: isCartLoading,
    isFetching: isCartFetching,
    isError: isCartError,
  } = useGetCart({eventToken} as GetCartQueryParams);

  const {t, i18n} = useTranslation();

  if (isCartError) return <div>{t('error.cart.get')}</div>;

  const products = data?.products ?? [];
  const employee = data?.employee ?? false;

    return (
      <div className="Details">
        <DocumentTitle
          title={`Soul Survivor ${event.name} | ${t('checkout.personal.information')}`}
        />

        <Breadcrumbs
          active="details"
          eventSlug={event.slug}
          employee={employee}
        />

        {products.length === 0 ? (
          <div className="Block CartEmpty">
            <p>
              {t('cart.cart_is_empty')}
            </p>

            <LinkContainer to={`/${i18n.language}/${event.slug}`}>
              <Button variant="light">
                <ChevronLeftIcon />
                {' '}
                {t('button.back_to_tickets')}
              </Button>
            </LinkContainer>
          </div>
        ) : (
          <Row>
            <Col lg={7} className="CheckOutColumn">
              <div className="DetailsBlock Block">
                <h2>{t('checkout.personal.information')}</h2>
                <div className="BlockExplanation">
                  {t('checkout.personal.subtext')}
                </div>

                <DetailsForm
                  eventSlug={event.slug}
                  eventToken={event.id}
                  employee={employee}
                />
              </div>
            </Col>
            <Col lg={5} className="CheckOutColumn">
              <div className="CartBlock">
                <SmallCart
                  eventSlug={event.slug}
                  eventToken={event.id}
                  track
                />
              </div>
            </Col>
          </Row>
        )}

        <CheckoutFooter />
      </div>
    );
  }

export default Details;