import React, {CSSProperties, ReactNode} from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import WarningIcon from '@material-ui/icons/Warning';
import InfoIcon from '@material-ui/icons/Info';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import {classNames} from '../../util';
import './Alert.scss';

interface AlertProps {
  type: 'error' | 'warning' | 'success' | 'info';
  icon?: React.ComponentType;
  children: ReactNode;
  style?: CSSProperties;
  inline?: boolean;
}

const Alert: React.FC<AlertProps> = ({
                                       type,
                                       icon,
                                       children,
                                       style,
                                       inline = false,
                                     }) => {
  let DisplayIcon = InfoIcon;

  // Determine the icon based on the type or the passed icon prop
  if (icon) {
    DisplayIcon = icon;
  } else if (type === 'error') {
    DisplayIcon = ErrorIcon;
  } else if (type === 'warning') {
    DisplayIcon = WarningIcon;
  } else if (type === 'success') {
    DisplayIcon = CheckCircleIcon;
  }

  return (
      <div
          className={classNames('Alert', type, inline && 'Alert_Inline', !inline && 'Alert_Block')}
          style={style}
      >
        <div className="AlertIcon">
          <DisplayIcon />
        </div>
        <div className="AlertMessage">{children}</div>
      </div>
  );
};

export default Alert;