import {useQuery} from "@tanstack/react-query";
import {handleInternalApiCall, METHOD_GET} from "../../services/api-requests/ApiCallHandler";
import {WorkGroupGroup} from "../../types/WorkGroup.type";

export type GetWorkgroupsQueryParams = {
    eventToken: string;
}

const QUERY_KEY = 'workgroups';

const fetchWorkgroups = async (params: GetWorkgroupsQueryParams): Promise<WorkGroupGroup[]> => {
    const response = await handleInternalApiCall(
        `event/${params.eventToken}/workgroup`,
        METHOD_GET,
        false,
        null
    );

    return response.data;
};

export const useGetWorkgroups = (params: GetWorkgroupsQueryParams) => {
    return useQuery<WorkGroupGroup[], Error>(useGetWorkgroupsQuery(params));
};

export const useGetWorkgroupsQuery = (params: GetWorkgroupsQueryParams) => {
    return {
        queryKey: [QUERY_KEY],
        queryFn: () => fetchWorkgroups(params)
    }
};