import {logError} from 'services/error-handling/ErrorHandler';
import {handleApiCall, METHOD_POST} from 'services/api-requests/ApiCallHandler';
import {useState} from "react";

export const useGetLoginMethod = () => {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [methods, setMethods] = useState<string[] | null>(null);

    const getMethod = async (email: string, onSuccess: (requiresPassword: boolean) => void) => {
        setIsLoading(true);

        try {
            const response = await handleApiCall('/login/method', METHOD_POST, false, {email});

            setMethods(response.data);
            const hasEmail = response.data.includes('EMAIL');
            const hasPassword = response.data.includes('PASSWORD');

            if (hasEmail && !hasPassword) {
                onSuccess(false);
                setError(false);
            } else if (hasEmail && hasPassword) {
                onSuccess(true);
                setError(false);
            } else {
                setError(true);
            }

            setIsLoading(false);

        } catch (error) {
            logError(error);

            setError(true);
            setIsLoading(false);
        }
    }

    return {
        getMethod,
        error,
        isLoading,
        methods
    };
};
