import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {handleApiCall, METHOD_POST} from "../../services/api-requests/ApiCallHandler";
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {FormikValues} from "formik";
import LocalStorageService from "../../services/storage/LocalStorageService";

export const useChangePassword = (t) => {
    const [validation, setValidation] = useState({});
    const [isSaved, setIsSaved] = useState(false);

    const submit = async (values: FormikValues, { setSubmitting }) => {
        setSubmitting(true);

        try {
            await handleApiCall(
                'me/change-password',
                METHOD_POST,
                true,
                {
                    currentPassword: values.currentPassword,
                    newPassword: values.newPassword,
                }
            );

            setValidation({});
            setIsSaved(true);
            LocalStorageService.handlePasswordChange();

        } catch (error) {
            if (error.response?.status === 400 && error.response.data.fields) {
                setValidation(error.response.data.fields);
            } else if (error.response?.status === 400 && error.response.data.message) {
                setValidation({});
                toast.error(<ToastContent type="error" message={error.response.data.message} />);
            } else {
                logError(error);
                setValidation({});
                toast.error(<ToastContent type="error" message={t('error.password.post')} />);
            }
        } finally {
            setSubmitting(false);
        }
    };

    return {
        submit,
        validation,
        isSaved,
    };
};

export default useChangePassword;