import React from 'react';
import Container from 'react-bootstrap/Container';
import {useTranslation} from 'react-i18next';
import {Route} from 'react-router-dom';
import EventHeader from 'components/EventHeader';
import Header from 'components/Header';
import Footer from 'components/Footer';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import LocalizedSwitch from 'components/LocalizedSwitch';
import Details from '../../Checkout/Details';
import Overview from '../../Checkout/Overview';
import CheckoutCart from '../../Checkout/CheckoutCart';
import PageNotFound from "../../Error/PageNotFound";
import {GetEventQueryParams, useGetEvent} from "../../../hooks/SingleEvent/useGetEvent";
import {RouteComponentProps} from "react-router";

interface EventProps extends RouteComponentProps<{
    eventSlug: string;
}> {
    children: React.ReactNode,
}

export const EventPageWrapper: React.FC<EventProps> = ({match, children}) => {
    const {t} = useTranslation();

    const {eventSlug} = match.params;

    const {
        data: event,
        isLoading: isEventLoading,
        isError: isEventError
    } = useGetEvent({eventSlug} as GetEventQueryParams);

    if (isEventLoading) return <FullScreenLoader route={match}/>;
    if (event === null) return <PageNotFound/>;
    if (isEventError) return <div>{t('error.event')}</div>;

    if (event === undefined) {
        return <div></div>;
    }

    return (
        <div className="Page">
            <Header route={match}/>

            <div className="Main">
                <EventHeader
                    name={event.name}
                    description={event.details.description}
                    fromDate={event.details.from}
                    tillDate={event.details.till}
                    venue={event.details.location.title}
                    small
                    websiteUrl={event.urls.website}
                    mapsUrl={event.urls.maps}
                />

                <Container>
                    {children}

                    <LocalizedSwitch>
                        <Route
                            exact
                            path="ticket.cart"
                            render={() => (
                                <CheckoutCart
                                    match={match}
                                    event={event}
                                />
                            )}
                        />
                        <Route
                            exact
                            path="ticket.checkout"
                            render={() => <Details event={event}/>}
                        />
                        <Route
                            exact
                            path="ticket.overview"
                            render={() => <Overview match={match} event={event}/>}
                        />
                    </LocalizedSwitch>
                </Container>
            </div>

            <Footer/>
        </div>
    );
}

export default EventPageWrapper;