import React from 'react';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {useTranslation} from 'react-i18next';
import {generatePath, Link} from 'react-router-dom';
import './Breadcrumbs.scss';

interface BreadcrumbsProps {
    active: 'cart' | 'details' | 'overview' | 'pay' | 'thanks';
    eventSlug: string;
    employee?: boolean;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({active, employee = false, eventSlug}) => {
    const {t, i18n} = useTranslation();

    // Set initial classes
    let classCart = '';
    let classDetails = '';
    let classPay = '';
    let classThanks = '';

    // Set classes based on active step
    if (active === 'cart') {
        classCart = ' Active';
    }

    if (active === 'details') {
        classCart += ' Completed';
        classDetails = ' Active';
    }

    if (active === 'overview' || active === 'pay') {
        classCart += ' Completed';
        classDetails += ' Completed';
        classPay = ' Active';
    }

    if (active === 'thanks') {
        classCart += ' Completed Disabled';
        classDetails += ' Completed Disabled';
        classPay += ' Completed Disabled';
        classThanks = ' Active';
    }

    return (
        <div className="Breadcrumbs">
            {/* Render cart step only for non-employees */}
            {employee !== true && (
                <Link
                    to={`/${i18n.language}${generatePath(t('routes:ticket.cart'), {eventSlug})}`}
                    className={`Breadcrumb${classCart}`}
                >
                    {t('order.process.cart')}
                </Link>
            )}

            {employee !== true && (
                <span className="BreadcrumbDivider"><ChevronRightIcon/></span>
            )}

            <Link
                to={`/${i18n.language}${generatePath(t('routes:ticket.checkout'), {eventSlug})}`}
                className={`Breadcrumb${classDetails}`}
            >
                {t('order.process.details')}
            </Link>

            <span className="BreadcrumbDivider">
                <ChevronRightIcon/>
            </span>

            <span className={`Breadcrumb${classPay}`}>
                {t('order.process.payment')}
            </span>

            <span className="BreadcrumbDivider">
                <ChevronRightIcon/>
            </span>

            <span className={`Breadcrumb${classThanks}`}>
                {t('order.process.thanks')}
            </span>
        </div>
    );
};

export default Breadcrumbs;