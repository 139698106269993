import React, {useMemo} from 'react';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';
import Input from 'components/Input/Formik/Input';
import FormItem from 'components/Form/FormItem';
import RadioGroup from 'components/Input/Formik/RadioGroup';
import './Form.scss';
import {Field, FormikValues} from "formik";
import CheckboxGroup from "../Input/Formik/CheckboxGroup";
import {Allergen, DietaryPreference, SoulMeals} from "../../types/SingleEvent.type";

type DietFormProps = {
    meals: SoulMeals,
    values: FormikValues,
    validation: Record<string, string[]>
}

const prepareDiets = (dietaryPreferences: DietaryPreference[], t) => {
    let options = dietaryPreferences.filter((preference) => preference.supportedByEvent).map((preference) => {
        return {
            label: preference.label,
            value: preference.id,
        }
    });

    options.sort((a, b) => a.label.localeCompare(b.label));
    options.push({label: t('form.label.other'), value: 'other'});

    return options;
}

const prepareAllergies = (allergens: Allergen[], t) => {
    let options = allergens.filter((allergen) => allergen.supportedByEvent).map((allergen) => {
        return {
            label: allergen.label,
            value: allergen.id,
        }
    })

    options.sort((a, b) => a.label.localeCompare(b.label));
    options.push({label: t('form.label.other'), value: 'other'});

    return options;
}

const DietForm: React.FC<DietFormProps> = ({meals, values, validation}) => {
    const {t} = useTranslation();

    const diets = useMemo(() => prepareDiets(meals.dietaryPreference, t), [meals, t])
    const allergies = useMemo(() => prepareAllergies(meals.allergens, t), [meals, t])

    return <div>
        <FormItem label={t('form.label.hasDiet')}>
            <Col md={12}>
                <Field
                    component={RadioGroup}
                    options={[
                        {
                            label: t('form.label.yes'),
                            value: "1",
                        },
                        {
                            label: t('form.label.no'),
                            value: "0",
                        },
                    ]}
                    variant="horizontal"
                    name="hasDiet"
                />
            </Col>
        </FormItem>

        {values.hasDiet === "1" && <>
            <FormItem label={t('form.label.diet')}>
                <Field
                    component={CheckboxGroup}
                    options={diets}
                    variant="vertical"
                    name="dietaryPreference"
                    columns={3}
                    serverError={validation}
                />
            </FormItem>
            {values.dietaryPreference.includes('other') && <>
                <FormItem label={t('form.label.other')} hideLabel>
                    <Col md={12}>
                        <Field
                            component={Input}
                            placeholder={t('form.label.other')}
                            name="customDietaryPreference"
                            serverError={validation}
                            type="textarea"
                            rows="2"
                        />
                    </Col>
                </FormItem>
            </>}
        </>}

        <FormItem label={t('form.label.hasAllergies')}>
            <Col md={12}>
                <Field
                    component={RadioGroup}
                    options={[
                        {
                            label: t('form.label.yes'),
                            value: "1",
                        },
                        {
                            label: t('form.label.no'),
                            value: "0",
                        },
                    ]}
                    variant="horizontal"
                    name="hasAllergies"
                />
            </Col>
        </FormItem>

        {values.hasAllergies === "1" && <>
            <FormItem label={t('form.label.allergies')}>
                <Col md={12}>
                    <Field
                        component={CheckboxGroup}
                        options={allergies}
                        variant="vertical"
                        name="dietaryRestrictions"
                        columns={3}
                        serverError={validation}
                    />
                </Col>
            </FormItem>
            {values.dietaryRestrictions.includes('other') && <>
                <FormItem label={t('form.label.other')} hideLabel>
                    <Col md={12}>
                        <Field
                            component={Input}
                            placeholder={t('form.label.other')}
                            name="customDietaryRestriction"
                            serverError={validation}
                            type="textarea"
                            rows="2"
                        />
                    </Col>
                </FormItem>
            </>}
        </>}
    </div>
};

export default DietForm;