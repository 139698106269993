import React, {useMemo} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';
import Input from 'components/Input/Formik/Input';
import FormItem from 'components/Form/FormItem';
import './Form.scss';
import {useGetUser} from "../../hooks/Session/useGetUser";
import {useChangePassword} from "../../hooks/Account/useChangePassword";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Loader from 'components/Loader';
import ButtonSpinner from "../Loader/ButtonSpinner";

const prepareSchema = (t) => {
    return Yup.object().shape({
        currentPassword: Yup.string()
            .trim()
            .required(t('form.validation.required')),
        newPassword: Yup.string()
            .trim()
            .required(t('form.validation.required')),
    });
}
const ChangePasswordForm: React.FC = () => {
    const {t} = useTranslation();
    const {data: user, isLoading, isError, error} = useGetUser();
    const {submit, validation, isSaved} = useChangePassword(t);
    const schema = useMemo(() => prepareSchema(t), [t])

    if (user === undefined || isLoading) {
        return <Loader className="FormLoader" />;
    }

    return (
        <Formik
            initialValues={{
                currentPassword: '',
                newPassword: '',
            }}
            validationSchema={schema}
            onSubmit={submit}
        >
            {({handleChange, handleBlur, values, setFieldValue, isSubmitting}) => (
                <Form>
                    {isSaved ? (
                        <div>
                            {t('account.password_changed')}
                        </div>
                    ) : (
                        <div>
                            {user.hasPassword && (
                                <FormItem label={t('form.label.password_current')}>
                                    <Col md={12}>
                                        <Field
                                            component={Input}
                                            placeholder={t('form.label.password_current')}
                                            serverError={validation}
                                            type="password"
                                            name="currentPassword"
                                        />
                                    </Col>
                                </FormItem>
                            )}
                            <FormItem label={t('form.label.password_new')}>
                                <Col md={12}>
                                    <Field
                                        component={Input}
                                        placeholder={t('form.label.password_new')}
                                        serverError={validation}
                                        type="password"
                                        name="newPassword"
                                    />
                                </Col>
                            </FormItem>

                            <Row className="Buttons">
                                <Col md={6}/>
                                <Col md={6}>
                                    <div className="ButtonRight">
                                        <Button variant="primary" disabled={isSubmitting} type="submit">
                                            {t('button.change_password')}
                                            <ButtonSpinner active={isSubmitting}/>
                                        </Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    )}
                </Form>
            )}
        </Formik>
    );
}

export default ChangePasswordForm;