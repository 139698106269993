import {useQuery} from "@tanstack/react-query";
import {handleApiCall, METHOD_GET} from "../../services/api-requests/ApiCallHandler";
import {User} from "../../types/User.type";
import LocalStorageService from 'services/storage/LocalStorageService';

const QUERY_KEY = 'user';

export const fetchUser = async (): Promise<User> => {
    if (!LocalStorageService.isLoggedIn()) {
        return null;
    }

    const response = await handleApiCall(
        `me`,
        METHOD_GET,
        true,
        null
    );

    return response.data as User;
};

export const useGetUser = () => {
    return useQuery<User, Error>(useGetUserQuery());
};

export const useGetUserQuery = () => {
    return {
        queryKey: [QUERY_KEY],
        queryFn: () => fetchUser()
    }
};