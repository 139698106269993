import React, {useMemo} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation} from 'react-i18next';
import './Form.scss';
import {generatePath} from 'react-router-dom';
import moment from 'moment';
import {LinkContainer} from 'react-router-bootstrap';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import FormItem from 'components/Form/FormItem';
import {isDate} from '../../util';
import ButtonSpinner from "../Loader/ButtonSpinner";
import {SoulMeals} from "../../types/SingleEvent.type";
import {SingleTicket} from "../../types/Account/SingleTicket.type";
import {Attendee} from "../../types/Attendee.type";
import DietForm from "./DietForm";
import {Field, Form, Formik} from "formik";
import * as Yup from "yup";
import Input from "../Input/Formik/Input";
import Checkbox from "../Input/Formik/Checkbox";
import useVisitorSubmit from "../../hooks/Order/useVisitorSubmit";
import useAssignVisitorToTicket from "../../hooks/Order/useAssignVisitorToTicket";
import useUpdateAttendeeDiet from "../../hooks/Order/useUpdateAttendeeDiet";
import Moment from "react-moment";

type AttendeeFormProps = {
    ticket: SingleTicket,
    attendee?: Attendee,
    orderSlug: string,
    meals: SoulMeals,
}

const prepareSchema = (t) => {
    return Yup.object().shape({
        firstName: Yup.string()
            .trim()
            .required(t('form.validation.required')),
        preposition: Yup.string()
            .trim(),
        lastName: Yup.string()
            .trim()
            .required(t('form.validation.required')),
        phoneNumber: Yup.string()
            .matches(/^[0-9]+$/, t('form.validation.invalid'))
            .required(t('form.validation.required')),
        email: Yup.string()
            .email(t('form.validation.invalid'))
            .required(t('form.validation.required')),
        dateOfBirth: Yup.string()
            .test('is-valid-date', t('form.validation.invalid'), (value) =>
                moment(value, 'DD-MM-YYYY', true).isValid()
            )
            .required(t('form.validation.required')),
        hasDiet: Yup.boolean().required(t('form.validation.required')),
        hasAllergies: Yup.boolean().required(t('form.validation.required')),
        dietaryPreference: Yup.array()
            .when('hasDiet', {
                is: true,
                then: (schema) => schema
                    .min(1, t('form.validation.required'))
                    .required(t('form.validation.required')),
                otherwise: (schema) => schema.nullable(),
            }),
        dietaryRestrictions: Yup.array()
            .when('hasAllergies', {
                is: true,
                then: (schema) => schema
                    .min(1, t('form.validation.required'))
                    .required(t('form.validation.required')),
                otherwise: (schema) => schema.nullable(),
            }),
        note: Yup.string()
            .trim(),
        approveUseDetails: Yup.boolean()
            .oneOf([true], t('form.validation.required'))
            .required(t('form.validation.required')),
    });
};

const prepareInitialValues = (attendee?: Attendee) => {

    let dietaryPreference = attendee ? attendee.diet.preference : [];
    let customDietaryPreference = attendee ? attendee.diet.customPreference : null;
    if(customDietaryPreference !== null) {
        dietaryPreference.push('other');
    } else {
        customDietaryPreference = '';
    }

    let dietaryRestrictions = attendee ? attendee.diet.restriction : [];
    let customDietaryRestriction = attendee ? attendee.diet.customRestriction : null;
    if(customDietaryRestriction !== null) {
        dietaryRestrictions.push('other');
    } else {
        customDietaryRestriction = '';
    }

    return {
        firstName: attendee ? attendee.firstName : '',
        preposition: attendee ? attendee.preposition : '',
        lastName: attendee ? attendee.lastName : '',
        phoneNumber: attendee ? (typeof attendee.phoneNumber !== 'undefined' ? attendee.phoneNumber.replace(/ /g, '') : '') : '',
        email: attendee ? attendee.email : '',
        dateOfBirth: attendee ? moment(attendee.dateOfBirth, 'YYYY-MM-DD').format('DD-MM-YYYY') : '',
        hasDiet: dietaryPreference.length > 0 ? "1" : "0",
        hasAllergies: dietaryRestrictions.length > 0 ? "1" : "0",
        dietaryPreference,
        customDietaryPreference,
        dietaryRestrictions,
        customDietaryRestriction,
        note: attendee ? attendee.note : '',
        approveUseDetails: attendee ? "1" : "0",
    }
}

const AttendeeForm: React.FC<AttendeeFormProps> = ({
                                                       ticket,
                                                       attendee,
                                                       orderSlug,
                                                       meals,
                                                   }) => {
    const {t, i18n} = useTranslation();
    const schema = useMemo(() => prepareSchema(t), [t])

    const {saveVisitor, validation: visitorValidation, isSaved: isVisitorSaved, savedVisitor} = useVisitorSubmit(orderSlug, t);
    const {assignVisitor, isLoading: isVisitorLoading, isSaved: isVisitorAssigned} = useAssignVisitorToTicket(orderSlug, ticket, t, i18n);
    const {updateDiet, validation: dietValidation, isLoading: isDietLoading, isSaved: isDietSaved} = useUpdateAttendeeDiet(ticket.eventToken, t, i18n);

    const validation = {...visitorValidation, ...dietValidation};

    const initialValues = useMemo(() => prepareInitialValues(savedVisitor !== null ? savedVisitor : ticket.attendee), [savedVisitor, ticket]);

    const handleAssign = async (visitorToken?: string) => {
        if (attendee === null) {
            await assignVisitor(visitorToken);
        } else {
            window.location.href = `/${i18n.language}${generatePath(t('routes:ticket.account_orders_order'), {orderSlug})}`;
        }
    };

    const handleSubmit = async (values, {setSubmitting}) => {
        await saveVisitor(attendee, values, { setSubmitting }, async (visitorToken) => {
            if(ticket.hasDietPreference) {
                await updateDiet(
                    visitorToken, values, () => handleAssign(visitorToken));
            } else {
                await handleAssign(visitorToken);
            }
        });
    };

    const canSpecifyDietaryRequirements = ticket.hasDietPreference && moment().isBefore(moment(meals.dueDate), 'day');

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={schema}
            onSubmit={handleSubmit}
        >
            {({handleChange, handleBlur, values, setFieldValue, isSubmitting, errors}) => {
                const age = isDate(values.dateOfBirth) ? moment().diff(moment(values.dateOfBirth, 'DD-MM-YYYY'), 'years') : 18;

                return <Form
                    id="PersonalizeTicketForm"
                >
                    <div className="FormExplanation">
                        {t('form.explanation.required')}
                    </div>
                    <FormItem label={t('form.label.name')} required>
                        <Col md={5}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.firstName')}
                                name="firstName"
                            />
                        </Col>
                        <Col md={2}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.preposition.short')}
                                name="preposition"
                            />
                        </Col>
                        <Col md={5}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.lastName')}
                                name="lastName"
                            />
                        </Col>
                    </FormItem>
                    <FormItem label={t('form.label.email')} required>
                        <Col md={12}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.email')}
                                name="email"
                            />
                        </Col>
                    </FormItem>
                    <FormItem label={t('form.label.phoneNumber')} required>
                        <Col md={12}>
                            <Field
                                component={Input}
                                placeholder={t('form.label.phoneNumber')}
                                name="phoneNumber"
                            />
                        </Col>
                    </FormItem>
                    <FormItem label={t('form.label.birthdate.long')} required>
                        <Col md={4}>
                            <Field
                                component={Input}
                                placeholder="DD-MM-YYYY"
                                name="dateOfBirth"
                                mask={[/\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            />
                        </Col>
                    </FormItem>

                    <div>
                        <hr/>

                        <h2>{t('personalize.notes.title')}</h2>
                        <div className="BlockExplanation">
                            {t('personalize.notes.subtitle')}
                            {ticket.hasDietPreference && <>
                                {canSpecifyDietaryRequirements ? <>
                                    {' '} {t('personalize.notes.diet')} {' '}
                                    <Moment date={meals.dueDate} locale={i18n.language} format="D MMMM YYYY" />.
                                </> : <>
                                    <br/><strong>{t('personalize.notes.diet_due')}</strong>
                                </>}
                            </>}
                        </div>
                    </div>

                    {canSpecifyDietaryRequirements && (
                        <div>
                            <DietForm meals={meals} values={values} validation={validation}/>
                        </div>
                    )}

                    <div>
                        <FormItem label={t('form.label.note')}>
                            <Col md={12}>
                                <Field
                                    component={Input}
                                    placeholder={t('form.label.note')}
                                    name="note"
                                    as="textarea"
                                    rows="5"
                                />
                            </Col>
                        </FormItem>

                        <hr/>

                        <FormItem>
                            <Col md={12}>
                                <Field
                                    component={Checkbox}
                                    label={`${t('form.label.approveUseDetails') + (age < 18 ? t(
                                        'form.label.approveUseDetailsParents',
                                    ) : '')}.`}
                                    name="approveUseDetails"
                                />
                            </Col>
                        </FormItem>

                    </div>

                    <Row className="Buttons">
                        <Col md={6}>
                            <LinkContainer
                                to={`/${i18n.language}${generatePath(t('routes:ticket.account_orders_order'),
                                    {orderSlug})}`}
                            >
                                <Button className="BackLink BackLinkBottom" variant="link">
                                    <ChevronLeftIcon/>
                                    {' '}
                                    {t('button.back_to_tickets')}
                                </Button>
                            </LinkContainer>
                        </Col>
                        <Col md={6}>
                            <div className="ButtonRight">
                                <Button variant="primary" disabled={isSubmitting || isVisitorLoading || isDietLoading} type="submit">
                                    {t('button.save')}
                                    <ButtonSpinner active={isSubmitting || isVisitorLoading || isDietLoading}/>
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Form>
            }
            }
        </Formik>
    );
}

export default AttendeeForm;