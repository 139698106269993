export const by = (key) => (object, item) => {
  const newObject = object;
  newObject[item[key]] = item;
  return newObject;
};

export const identity = (x) => x;

export const classNames = (...args) => args.filter(identity).join(' ');

export const debounce = (fn, delay) => {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  };
};

export const log = (value, prefix = '') => {
  console.log(prefix, value);
  return value;
};

export const preventDefault = (e) => {
  e.preventDefault();
};

export const getEnvironment = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return 'dev';
  }
  return 'prod';
};

export const isProductionEnvironment = () => {
  return getEnvironment() === 'prod';
}

export const isEmpty = (object) => Object.values(object).every((x) => x === null || x === '');

export const getRelativeUrl = (url) => {
  let newUrl = url.replace(/^[a-z]{4,5}:\/{2}[a-z]+:[0-9]{1,4}.(.*)/, '$1');

  if (newUrl.charAt(0) === '/') {
    newUrl = newUrl.slice(1);
  }

  return newUrl;
};

export const toggleElement = (arr, val) =>
    arr.includes(val) ? arr.filter((el) => el !== val) : [...arr, val];

export const isDate = (date) => {
  const regex = /^([0-2][0-9]|(3)[0-1])(-)(((0)[0-9])|((1)[0-2]))(-)\d{4}$/i;
  return regex.test(date);
};