import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import {useTranslation} from 'react-i18next';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import SubArrowIcon from '@material-ui/icons/SubdirectoryArrowRight';
import MoneyString from 'components/MoneyString/index';
import CartRemoveMessage from 'components/Cart/CartRemoveMessage';
import {useChangeCartItem} from "../../hooks/Cart/useChangeCartItem";
import {Voucher} from "../../types/Voucher.type";

type VoucherRowProps = Voucher &  {
    eventToken: string,
}

const VoucherRow: React.FC<VoucherRowProps> = ({ eventToken, code, name, amount, percent, discountType }) => {
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
    const {t, i18n} = useTranslation();

    const {removeItem} = useChangeCartItem({
        eventToken,
        errorMessage: t('error.cart.edit')
    });

    const handleRemove = () => {
        setShowRemoveConfirmation(true);
    };

    const handleRemoveCancel = () => {
        setShowRemoveConfirmation(false);
    };

    const handleRemoveConfirm = () => {
        setShowRemoveConfirmation(false);
        removeItem(code, true);
    };

    return (
        <div className="VoucherRow" key={`voucher-${code}`}>
            <SubArrowIcon />
            {discountType === 'DISCOUNT_PRICE' && <span>
                1x {' '}
                {name} {' - '}
                <span className="VoucherPrice">
                    <MoneyString amount={amount.amount} currency={amount.currency} />
                    {' '}
                    {t('cart.discount_on')}
                </span>
            </span>}
            {discountType === 'DISCOUNT_PERCENT' && <span>
                1x {' '}
                {name} {' - '}
                <span className="VoucherPrice">
                    {Math.round(percent)}%
                    {' '}
                    {t('cart.discount_on')}
                </span>
            </span>}
            {discountType === 'NEW_PRICE' && <span>
                1x {' '}
                {name} {' '}
                {t('cart.for')}
                {' '}
                <span className="VoucherPrice">
                    <MoneyString amount={amount.amount} currency={amount.currency}/>
                </span>
            </span>}
                {' '}
                <span className="VoucherCode">
            (<VoucherIcon/> {code})
            </span>

            {showRemoveConfirmation && (
                <CartRemoveMessage
                    cancel={handleRemoveCancel}
                    confirm={handleRemoveConfirm}
                    voucher
                />
            )}

            <Button
                variant="link"
                className="RemoveButton"
                onClick={handleRemove}
            >
                <DeleteIcon className="RemoveButtonIcon"/>
            </Button>
        </div>
    );
};

export default VoucherRow;