import Spinner from "react-bootstrap/Spinner";
import React from "react";
import {classNames} from "../../util";

type ButtonSpinnerProps = {
    active: boolean,
    icon: boolean
}

export const ButtonSpinner: React.FC<ButtonSpinnerProps> = ({active, icon = false}) => {
    if (!active) {
        return null;
    }

    return <span className={classNames('ButtonSpinner', icon && 'ButtonSpinner_Icon')}>
        <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
        />
    </span>;
}

export default ButtonSpinner;