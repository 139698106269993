import React from 'react';
import PropTypes from 'prop-types';
import EventTicket from 'components/EventTickets/EventTicket';

import './EventTickets.scss';

const EventTickets = ({
  tickets, eventSlug, eventToken, cartAmount, trigger,
}) => (
  <div className="EventTickets">
    {tickets && tickets.length > 0 ? tickets.map((ticket) => (
      <EventTicket
        token={ticket.token}
        key={ticket.token}
        eventSlug={eventSlug}
        eventToken={eventToken}
        cartAmount={cartAmount}
        trigger={trigger}
        name={ticket.name}
        varieties={ticket.varieties}
      />
    )) : ''}
  </div>
);

export default EventTickets;

EventTickets.propTypes = {
  tickets: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    dietPreference: PropTypes.bool.isRequired,
    minQuantity: PropTypes.number,
    maxQuantity: PropTypes.number,
    varieties: PropTypes.arrayOf(PropTypes.shape({
      token: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      capacity: PropTypes.number,
      priceIncludingVat: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
      }).isRequired,
      discountedPriceIncludingVat: PropTypes.shape({
        currency: PropTypes.string.isRequired,
        amount: PropTypes.string.isRequired,
      }),
      validFrom: PropTypes.string.isRequired,
      validTill: PropTypes.string.isRequired,
      openByDefault: PropTypes.bool.isRequired,
      discounts: PropTypes.arrayOf(PropTypes.shape({
        type: PropTypes.string.isRequired,
        active: PropTypes.bool.isRequired,
        validFrom: PropTypes.string.isRequired,
        validTill: PropTypes.string.isRequired,
        amount: PropTypes.shape({
          currency: PropTypes.string.isRequired,
          amount: PropTypes.string.isRequired,
        }).isRequired,
        min: PropTypes.number,
        max: PropTypes.number,
      })),
    })).isRequired,
  })).isRequired,
  eventSlug: PropTypes.string.isRequired,
  eventToken: PropTypes.string.isRequired,
  cartAmount: PropTypes.objectOf(PropTypes.number).isRequired,
  trigger: PropTypes.func.isRequired,
};
