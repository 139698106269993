import {generatePath} from 'react-router-dom';
import {useMutation} from '@tanstack/react-query';
import LocalStorageService from 'services/storage/LocalStorageService';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleApiCall, METHOD_POST} from 'services/api-requests/ApiCallHandler';
import i18n from '../../i18n';
import {removeQuery} from 'services/routing/query-params';
import {JsonWebToken} from "../../types/JsonWebToken.type";

export const useLoginByToken = () => {
    return useMutation<JsonWebToken, Error, string>(useLoginByTokenMutation());
};

export const useLoginByTokenMutation = () => {
    return {
        mutationKey: ['LOGIN_TOKEN'],
        mutationFn: login,
    }
};

const login = async (token: string): Promise<JsonWebToken> => {
    LocalStorageService.clearToken();

    try {
        const response = await handleApiCall('/login/key', METHOD_POST, false, {key: token});

        LocalStorageService.setToken({
            access_token: response.data.token,
            refresh_token: response.data.refreshToken,
        });

        removeQuery('key');

        return response.data;
    } catch (error) {
        logError(error);

        if (error.response) {
            if (error.response.status === 401) {
                if (error.response.data.error === 910) {
                    window.location.href = `/${i18n.language}${generatePath(`${i18n.t('routes:ticket.account_login')}?e=key_invalid`)}`;
                } else if (error.response.data.error === 911) {
                    window.location.href = `/${i18n.language}${generatePath(`${i18n.t('routes:ticket.account_login')}?e=key_expired`)}`;
                }
            } else if (error.response.status === 400) {
                window.location.href = `/${i18n.language}${generatePath(`${i18n.t('routes:ticket.account_login')}?e=key_invalid`)}`;
            }
        }
    }
}

// const useLogInByUsernameAndPassword = (onError) => {
//     const queryClient = useQueryClient();
//
//     return useMutation(
//         async ({ username, password }) => {
//             LocalStorageService.clearToken();
//
//             const response = await handleApiCall('/login', METHOD_POST, false, { username, password });
//             LocalStorageService.setToken({
//                 access_token: response.data.token,
//                 refresh_token: response.data.refreshToken,
//             });
//
//             const me = await handleApiCall('/me', METHOD_GET, true, null);
//             LocalStorageService.setUser(me.data);
//             return me.data;
//         },
//         {
//             onSuccess: () => {
//                 queryClient.invalidateQueries('user');
//             },
//             onError: (error) => {
//                 onError(error);
//                 logError(error);
//             },
//         }
//     );
// };

// export { useLoginByToken };
