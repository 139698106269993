import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import {useTranslation} from 'react-i18next';
import MoneyString from 'components/MoneyString';
import {Amount} from "../../types/Amount.type";
import {Voucher} from "../../types/Voucher.type";
import {Discount} from "../../types/Discount.type";

interface CartItemProps {
    quantity: number;
    name: string;
    varietyName: string | null;
    originalPrice: Amount;
    unitPrice: Amount;
    totalPrice: Amount;
    vouchers: Voucher[];
    discounts: Discount[];
}

const CartItem: React.FC<CartItemProps> = ({
                                               name,
                                               varietyName,
                                               quantity,
                                               originalPrice,
                                               unitPrice,
                                               totalPrice,
                                               discounts,
                                               vouchers,
                                           }) => {
    const { t } = useTranslation();

    const hasDiscount = originalPrice.amount !== unitPrice.amount;
    let discountTypes: Discount[] = [];

    if (hasDiscount && discounts !== null) {
        discountTypes = discounts.filter((discount) => discount.active);
    }

    const originalTotalPrice = parseFloat(originalPrice.amount) * quantity;
    const amountDiscount = vouchers ? vouchers.length : 0;
    const amountNoDiscount = quantity - amountDiscount;

    const priceNoDiscount: Amount = {
        amount: (parseFloat(unitPrice.amount) * amountNoDiscount).toString(),
        currency: unitPrice.currency,
    };

    return (
        <div className="CartItem">
            <Row>
                <Col className="CartItemQuantity" xs={1}>
                    {quantity} x
                </Col>
                <Col className="CartItemName" xs={7}>
                    <div className="CartItemNameTicket">{name}</div>
                    {varietyName && <div className="CartItemNameVariety">{varietyName}</div>}

                    {discountTypes.length > 0 && (
                        <div className="DiscountInformation">
                            {discountTypes.map((discount, index) => (
                                <div className="DiscountBadge" id={`discount-${index}`} key={discount.type}>
                                    {t(`cart.discountTypes.${discount.type.toLowerCase()}`)}
                                </div>
                            ))}
                        </div>
                    )}
                </Col>
                <Col className="CartItemPrice" xs={4}>
                    {discountTypes.length > 0 ? (
                        <div className="DiscountedPrice">
                            <div className="CurrentPrice">
                                <MoneyString amount={totalPrice.amount} currency={totalPrice.currency} />
                            </div>
                            <div className="OldPrice">
                                <MoneyString amount={originalTotalPrice.toString()} currency={totalPrice.currency} />
                            </div>
                        </div>
                    ) : (
                        <MoneyString amount={totalPrice.amount} currency={totalPrice.currency} />
                    )}
                </Col>
            </Row>

            {vouchers && vouchers.length > 0 && (
                <div className="VoucherInformation">
                    {vouchers.map((voucher) => (
                        <Row className="VoucherRow" key={`voucher-${voucher.code}`}>
                            <Col xs={1} />
                            <Col className="CartItemQuantity" xs={1}>
                                1x
                            </Col>

                            <Col className="CartItemName VoucherName" xs={6}>
                            {voucher.name}{' '}
                                <span className="VoucherCode">
                                  (<VoucherIcon /> {voucher.code})
                                </span>
                            </Col>
                            <Col className="CartItemPrice" xs={4}>
                                {voucher.discountType === 'DISCOUNT_PRICE' && <span>
                                    {'- '}<MoneyString amount={voucher.amount.amount} currency={voucher.amount.currency} />
                                </span>}
                                {voucher.discountType === 'DISCOUNT_PERCENT' && <span>
                                    {'- '}{Math.round(voucher.percent)}%
                                    {' '}
                                </span>}
                                {voucher.discountType === 'NEW_PRICE' && <span>
                                    <MoneyString amount={voucher.amount.amount} currency={voucher.amount.currency} />
                                </span>}
                            </Col>
                        </Row>
                    ))}
                    {amountNoDiscount > 0 && (
                        <Row className="VoucherRow">
                            <Col xs={1} />
                            <Col className="CartItemQuantity" xs={1}>
                                {amountNoDiscount} x
                            </Col>
                            <Col className="CartItemName VoucherName" xs={6}>
                                <div className="CartItemNameTicket">{name}</div>
                                {varietyName && <div className="CartItemNameVariety">{varietyName}</div>}
                            </Col>
                            <Col className="CartItemPrice" xs={4}>
                                <MoneyString amount={priceNoDiscount.amount} currency={priceNoDiscount.currency} />
                            </Col>
                        </Row>
                    )}
                </div>
            )}
        </div>
    );
};

export default CartItem;