import React, {useMemo} from 'react';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useTranslation, withTranslation} from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import Input from 'components/Input/Formik/Input';
import {Field, Form, Formik} from "formik";
import {useApplyVoucher} from "../../hooks/Cart/useApplyVoucher";
import * as Yup from "yup";
import ButtonSpinner from "../Loader/ButtonSpinner";

type VoucherFormProps = {
    eventToken: string,
    toggleVoucherClick: () => void,
}

const prepareSchema = (t) => {
    return Yup.object().shape({
        voucherCode: Yup.string()
            .trim()
            .required(t('form.validation.required'))
    });
}

const VoucherForm: React.FC<VoucherFormProps> = ({eventToken, toggleVoucherClick}) => {
    // componentDidMount() {
    //   const urlParams = queryString.parse(window.location.search);
    //
    //   if (typeof urlParams.voucher !== 'undefined') {
    //     this.addVoucher(urlParams.voucher);
    //     removeQuery('voucher');
    //   }
    // }

    const {t} = useTranslation();

    const {applyVoucher} = useApplyVoucher({
        eventToken,
        t
    });

    const schema = useMemo(() => prepareSchema(t), [t])

    return (
        <Formik
            initialValues={{
                voucherCode: '',
            }}
            onSubmit={(values, formikHelpers) => applyVoucher(values, formikHelpers, toggleVoucherClick)}
            validationSchema={schema}
        >
            {({handleChange, handleBlur, values, setFieldValue, isSubmitting}) => {
                return <Form>
                    <Row className="CartVoucherInput">
                        <Col xs={9}>
                            <Field
                                component={Input}
                                autoFocus
                                placeholder={t('cart.voucher')}
                                name="voucherCode"
                            />
                        </Col>
                        <Col xs={2}>
                            <Button
                                className="AddVoucherButton"
                                variant="success"
                                type="submit"
                                disabled={values.voucherCode === '' || isSubmitting}
                            >
                                {isSubmitting ?
                                    <ButtonSpinner icon active={isSubmitting}/> :
                                    <AddIcon/>
                                }
                            </Button>
                        </Col>
                        <Col xs={1}>
                            <Button
                                className="CartVoucherInputClose"
                                variant="link"
                                onClick={toggleVoucherClick}
                            >
                                <CloseIcon className="CartVoucherInputCloseIcon"/>
                            </Button>
                        </Col>
                    </Row>
                </Form>
            }}
        </Formik>
    );
}

export default withTranslation()(VoucherForm);