import React from 'react';
import Form from 'react-bootstrap/Form';
import {classNames} from '../../util';

interface FormItemProps {
    label?: string;
    children: React.ReactNode;
    hidden?: boolean;
    required?: boolean;
    className?: string;
    hideLabel?: boolean;
}

const FormItem: React.FC<FormItemProps> = ({
                                               label,
                                               children,
                                               hidden = false,
                                               required = false,
                                               className = '',
                                               hideLabel = false,
                                           }) => (
    <div className={classNames('FormItem', hidden && 'Hidden', className)}>
        {!hideLabel && (
            <Form.Row>
                <Form.Label className="FormLabel">
                    {label}
                    {required && <span className="FormFieldRequiredAsterisk">*</span>}
                </Form.Label>
            </Form.Row>
        )}
        <Form.Row>{children}</Form.Row>
    </div>
);

export default FormItem;