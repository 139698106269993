import React, {useEffect} from 'react';

import {Router} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import LocalizedRouter from 'components/LocalizedRouter';
import TicketRoutes from 'routes/TicketRoutes';
import ScrollToTop from 'components/ScrollToTop';
import 'scss/index.scss';
import './App.scss';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import {useGetCartUuid} from "../../hooks/Cart/useGetCartUuid";
import ErrorPage from "../../pages/Error/ErrorPage";
import {useTranslation} from "react-i18next";
import queryString from "query-string";
import LoginLoader from 'components/Loader/LoginLoader';
import {useLoginByToken} from "../../hooks/Session/useLoginByToken";
import {useGetUser} from "../../hooks/Session/useGetUser";

export const App: React.FC = () => {
    const {
        isLoading: isLoading,
        isError: isError
    } = useGetCartUuid();

    const {
        mutate: logIn,
        isSuccess,
        isPending: isLoggingIn,
        isError: isLoginError,
        error
    } = useLoginByToken();

    const { data: user, isLoading: isLoadingUser } = useGetUser();

    const {t} = useTranslation();
    const urlParams = queryString.parse(location.search);
    const key = urlParams.key;

    useEffect(() => {
        if (urlParams.key) {
            logIn(key as string);
        }
    }, [key]);

    if (isLoading || isLoadingUser) {
        return <FullScreenLoader/>;
    }

    if (isError) return <ErrorPage title={t('error.title')} text={t('error.cart.get')}/>;
    if (isLoginError) return <ErrorPage title={t('error.title')} text={t('error.login.general')}/>;

    if (isLoggingIn) {
        // If token exists in URL and user is not logged in, trigger the login process
        return <LoginLoader />;
    }

    return (
        <div className="App">
            <LocalizedRouter
                RouterComponent={Router}
            >
                <ScrollToTop/>
                <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    draggable={false}
                    pauseOnHover
                />
                <TicketRoutes/>
            </LocalizedRouter>
        </div>
    );
}

export default App;