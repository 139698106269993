import {logError} from 'services/error-handling/ErrorHandler';
import {handleApiCall, METHOD_POST} from 'services/api-requests/ApiCallHandler';
import {useState} from "react";

export const useSendLoginEmail = (redirectUrl: string) => {
    const [error, setError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isSent, setIsSent] = useState(false);

    const sendEmail = async (email: string) => {
        setIsLoading(true);

        try {
            const response = await handleApiCall('/login/key/request', METHOD_POST, false, {email, redirectUrl});

            setIsSent(true);
            setError(false);
        } catch (error) {
            logError(error);

            setError(true);
            setIsLoading(false);
        }
    }

    return {
        sendEmail,
        error,
        isLoading,
        isSent,
    };
};
