import React, {useState} from 'react';
import {toast} from 'react-toastify';
import {handleInternalApiCall, METHOD_PUT,} from "../../services/api-requests/ApiCallHandler";
import ToastContent from 'components/ToastContent';
import {logError} from 'services/error-handling/ErrorHandler';
import {FormikValues} from "formik";

export const useUpdateAttendeeDiet = (eventToken: string, t, i18n) => {
    const [validation, setValidation] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSaved, setIsSaved] = useState(false);

    const updateDiet = async (attendeeToken: string, values: FormikValues, onSuccess: () => void) => {
        setIsLoading(true);

        try {
            await handleInternalApiCall(
                `event/${eventToken}/attendee/${attendeeToken}/diet`,
                METHOD_PUT,
                true,
                {
                    dietaryRestrictions: values.hasAllergies === "1" ? values.dietaryRestrictions.filter((item) => item !== 'other') : [],
                    customDietaryRestriction: values.hasAllergies === "1" ? (values.dietaryRestrictions.includes('other') ? values.customDietaryRestriction : null) : null,
                    dietaryPreference: values.hasDiet === "1" ? values.dietaryPreference.filter((item) => item !== 'other') : [],
                    customDietaryPreference: values.hasDiet === "1" ? (values.dietaryPreference.includes('other') ? values.customDietaryPreference : null) : null,
                },
            );

            setIsLoading(false);
            setIsSaved(true);

            onSuccess();
        } catch (error) {
            if (error.response?.status === 400 && error.response.data.fields) {
                setValidation(error.response.data.fields);
            } else if (error.response?.status === 400 && error.response.data.message) {
                setValidation({});
                toast.error(<ToastContent type="error" message={error.response.data.message} />);
            } else {
                logError(error);
                setValidation({});
                toast.error(<ToastContent type="error" message={t('error.personalize.post')} />);
            }

            setIsLoading(false);
        }
    };

    return {
        updateDiet,
        validation,
        isLoading,
        isSaved,
    };
};

export default useUpdateAttendeeDiet;