import queryString from 'query-string';
import {isEmpty} from '../../util';
import {handleInternalApiCall, METHOD_POST} from "../api-requests/ApiCallHandler";
import LocalStorageService from "../storage/LocalStorageService";
import {Amount} from "../../types/Amount.type";

export const trackInFacebook = (type, data = null) => {
  // @ts-ignore
  if (typeof window.fbq !== 'undefined') {
    if (data !== null) {
      // @ts-ignore
      window.fbq('track', type, data);
    } else {
      // @ts-ignore
      window.fbq('track', type);
    }
  }
};

export const trackInMatomo = (data) => {
  // @ts-ignore
  // eslint-disable-next-line no-underscore-dangle
  const _paq = window._paq || [];
  _paq.push(data);
};

export const trackPageView = (location) => {
  // Matomo
  trackInMatomo(['setCustomUrl', location]);
  trackInMatomo(['setDocumentTitle', document.title]);
  trackInMatomo(['trackPageView']);
};

export const trackPurchase = (order) => {
  trackInFacebook('Purchase', {
    value: order.originalTotalPriceIncludingVat.amount,
    currency: order.originalTotalPriceIncludingVat.currency,
  });

  // event.tickets.forEach((ticket) => {
  //   trackInMatomo(['addEcommerceItem',
  //     item.token, // (Required) productSKU
  //     varietyName !== null ? item.name + ' - ' + item.varietyName : item.name,
  //      (Optional) productName
  //     [], // (Optional) productCategory
  //     item.unitPrice.amount, // (Recommended) price
  //     item.quantity, // (Optional, defaults to 1) quantity
  //   ]);
  // });

  // trackInMatomo(['trackEcommerceCartUpdate', totalPrice.amount]);
};

export const trackInitiateCheckOut = (totalPrice: Amount) => {
  trackInFacebook('InitiateCheckout', {
    value: totalPrice.amount,
    currency: totalPrice.currency,
  });
};

export const trackAddToCart = (product, amount) => {
  trackInFacebook('AddToCart', {
    contents: [
      {
        id: product,
        quantity: amount,
      },
    ],
  });
};

export const trackCartChange = (items, totalPrice) => {
  if(items === null) {
    return;
  }

  items.forEach((item) => {
    trackInMatomo(['addEcommerceItem',
      item.token, // (Required) productSKU
      item.varietyName !== null ? `${item.name} - ${item.varietyName}` : item.name, // (Optional) productName
      [], // (Optional) productCategory
      item.unitPrice.amount, // (Recommended) price
      item.quantity, // (Optional, defaults to 1) quantity
    ]);
  });

  trackInMatomo(['trackEcommerceCartUpdate', totalPrice.amount]);
};

export const trackAddPaymentInfo = () => {
  trackInFacebook('AddPaymentInfo');
};

export const getMarketingUrlParams = () => {
  const urlParams = queryString.parse(window.location.search);

  const marketingParams = {
    campaign: urlParams.mtm_campaign || null,
    source: urlParams.mtm_source || null,
    medium: urlParams.mtm_medium || null,
    keyword: urlParams.mtm_keyword || null,
    content: urlParams.mtm_content || null,
    cid: urlParams.mtm_cid || null,
    group: urlParams.mtm_group || null,
    placement: urlParams.mtm_placement || null,
  };

  return !isEmpty(marketingParams) ? marketingParams : false;
};

export const trackMarketingCampaign = (eventToken) => {
  const marketingParams = getMarketingUrlParams();

  if (marketingParams) {
    handleInternalApiCall(`shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/campaign`,
        METHOD_POST,
        false,
        {
          source: marketingParams.source,
          campaign: marketingParams.campaign,
          medium: marketingParams.medium,
          content: marketingParams.content,
          term: marketingParams.keyword,
        });
  }
}
