import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import PropTypes from 'prop-types';
import {generatePath, Redirect} from 'react-router-dom';
import CheckoutFooter from 'components/CheckoutFooter';
import DocumentTitle from 'components/DocumentTitle';
import LargeCart from 'components/Cart/LargeCart';
import Breadcrumbs from 'components/Breadcrumbs';

import './CheckoutCart.scss';

class CheckoutCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employee: false,
    };
  }

  updateEmployee = (employee) => {
    this.setState({
      employee,
    });
  };

  render() {
    const { event, i18n, t } = this.props;
    const { employee } = this.state;

    if (employee === true) {
      return (
        <Redirect
          to={`/${i18n.language}${generatePath(t('routes:ticket.checkout'),
            { eventSlug: event.slug })}`}
        />
      );
    }

    return (
      <div className="CheckoutCart">
        <DocumentTitle title={`Soul Survivor ${event.name} | ${t('cart.title')}`} />

        <Breadcrumbs
          active="cart"
          eventSlug={event.slug}
          eventUrl={event.urls.website}
        />

        <LargeCart
          eventSlug={event.slug}
          eventToken={event.id}
          onUpdate={this.updateEmployee}
        />

        <CheckoutFooter />
      </div>
    );
  }
}

export default withTranslation()(CheckoutCart);

CheckoutCart.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  event: PropTypes.shape({
    name: PropTypes.string,
    slug: PropTypes.string,
    token: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
};
