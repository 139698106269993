import React from 'react';
import {Col, Container, Row} from 'react-bootstrap';
import DocumentTitle from 'components/DocumentTitle';
import './Login.scss';
import Header from 'components/Header';
import Footer from 'components/Footer';
import {RouteComponentProps} from "react-router";
import LoginForm from "../../components/Form/LoginForm";

interface LoginProps extends RouteComponentProps {
}

const Login: React.FC<LoginProps> = ({match}) => {
    return (
        <div className="Login">
            <DocumentTitle title="Soul Survivor - Login"/>

            <Header route={match}/>

            <div className="Main">

                <Container>
                    <Row>
                        <Col md={{span: 6, offset: 3}} className="CheckOutColumn">
                            <div className="LoginBlock">
                                <LoginForm/>
                            </div>
                        </Col>
                        <Col md={6}/>
                    </Row>

                </Container>
            </div>

            <Footer/>
        </div>
    );
}

export default Login;