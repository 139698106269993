import React, {Component} from 'react';
import Container from 'react-bootstrap/Container';
import {withTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import './AccountTicketsOrder.scss';
import LocalMallOutlinedIcon from '@material-ui/icons/LocalMallOutlined';
import ConfirmationNumberOutlinedIcon from '@material-ui/icons/ConfirmationNumberOutlined';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Moment from 'react-moment';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {LinkContainer} from 'react-router-bootstrap';
import {generatePath} from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Spinner from 'react-bootstrap/Spinner';
import {saveAs} from 'file-saver';
import GetAppIcon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import DocumentTitle from 'components/DocumentTitle';
import TicketItem from 'components/AccountOrderOverview/TicketItem';
import LargeHeader from 'components/LargeHeader';
import ToastContent from 'components/ToastContent';
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import Footer from 'components/Footer';
import Header from 'components/Header';
import TicketDownloadConfirmDialog from 'components/ConfirmDialog/TicketDownloadConfirmDialog';
import {logError} from 'services/error-handling/ErrorHandler';
import {handleLegacyInternalApiCall, METHOD_GET} from 'services/api-requests/ApiCallHandler';

class AccountOrdersOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      order: null,
      tickets: [],
      isLoading: true,
      downloadDisabled: false,
      downloadConfirm: false,
      countPersonalized: 0,
      countPersonalizable: 0,
    };
  }

  componentDidMount() {
    const { match } = this.props;
    this.setState({ isLoading: true });

    this.getOrder(match.params.orderSlug, () => {
      this.getTickets(match.params.orderSlug);
    });
  }

  getTicketPDF = (orderSlug, override = false) => {
    const { t } = this.props;
    const { countPersonalized, countPersonalizable, order } = this.state;

    if (countPersonalized === countPersonalizable || override) {
      this.setState({
        downloadDisabled: true,
        downloadConfirm: false,
      });

      handleLegacyInternalApiCall(`personalize/order/${orderSlug}/pdf`,
        METHOD_GET,
        true,
        null,
        (response) => {
          const fileName = `Tickets-${order.event.name}.pdf`;
          saveAs(new Blob([response.data], { type: 'application/pdf;charset=utf-8' }), fileName);

          this.setState({
            downloadDisabled: false,
          });
        },
        (error) => {
          logError(error);
          toast.error(<ToastContent type="error" message={t('error.ticket.pdf')} />);

          this.setState({
            downloadDisabled: true,
            downloadConfirm: false,
            isLoading: false,
          });
        },
        { responseType: 'blob' });
    } else {
      this.setState({
        downloadConfirm: true,
      });
    }
  };

  cancelDownload = () => {
    this.setState({
      downloadConfirm: false,
    });
  };

  getOrder = (orderSlug, callback = () => {
  }) => {
    const { t } = this.props;
    handleLegacyInternalApiCall(`shop/order/${orderSlug}`, METHOD_GET, true, null, (response) => {
      this.setState({
        order: response.data,
      });

      callback();
    }, (error) => {
      logError(error);
      toast.error(<ToastContent type="error" message={t('error.order.get')} />);
      this.setState({ isLoading: false });
    });
  };

  getTickets = (orderSlug) => {
    const { t } = this.props;
    handleLegacyInternalApiCall(`order/${orderSlug}/tickets`,
      METHOD_GET,
      true,
      null,
      (response) => {
        const tickets = response.data;

        let countPersonalized = 0;
        let countPersonalizable = 0;

        for (let i = 0; i < tickets.length; i += 1) {
          if (tickets[i].isPersonalizable) {
            countPersonalizable += 1;

            if (tickets[i].attendee) {
              countPersonalized += 1;
            }
          }
        }

        this.setState({
          tickets,
          isLoading: false,
          countPersonalized,
          countPersonalizable,
        });
      },
      (error) => {
        logError(error);
        toast.error(<ToastContent type="error" message={t('error.order.get')} />);
        this.setState({ isLoading: false });
      });
  };

  render() {
    const { t, i18n, match } = this.props;
    const {
      isLoading, order, tickets, downloadConfirm, downloadDisabled,
    } = this.state;

    if (isLoading === true) return <FullScreenLoader route={match} />;

    return (
      <div className="Account AccountOrdersOrder">
        <Header route={match} />
        <DocumentTitle
          title={`Soul Survivor | ${t('account.order')} #${order.order.orderNumber}`}
        />
        <LargeHeader title={`${t('account.order_for')} ${order.event.name}`}>
          <div className="LargeHeaderInformation">
            <LocalMallOutlinedIcon className="LargeHeaderInformationIcon" />
            {t('account.table.order')}
            {' '}
            #
            {order.order.orderNumber}
          </div>
          <div className="LargeHeaderInformation">
            <ConfirmationNumberOutlinedIcon className="LargeHeaderInformationIcon" />
            {tickets.length}
            {' '}
            {tickets.length > 1 ? t('account.tickets') : t('account.ticket')}
          </div>
          <div className="LargeHeaderInformation">
            <DateRangeIcon className="LargeHeaderInformationIcon" />
            <Moment
              date={order.event.details.from}
              locale={i18n.language}
              format="D MMM YYYY"
            />
            {order.event.details.from !== order.event.details.till && (
              <span>
&nbsp;
                {t('event.date_till')}
                {' '}
                <Moment
                  date={order.event.details.till}
                  locale={i18n.language}
                  format="D MMM YYYY"
                />
              </span>
            )}
          </div>
        </LargeHeader>

        <div className="Main">

          <Container>
            <Row className="Buttons">
              <Col md={6}>
                <LinkContainer
                  to={`/${i18n.language}${generatePath(t('routes:ticket.account_orders'))}`}
                >
                  <Button className="BackLink BackLinkBottom" variant="link">
                    <ChevronLeftIcon />
                    {' '}
                    {t('button.back_to_orders')}
                  </Button>
                </LinkContainer>
              </Col>
              <Col md={6} className="AccountOrderButtons">
                {downloadConfirm && (
                  <TicketDownloadConfirmDialog
                    confirm={() => {
                      this.getTicketPDF(order.order.orderToken, true);
                    }}
                    cancel={this.cancelDownload}
                  />
                )}
                <Button
                  variant="link"
                  className="AccountOrderButton"
                  disabled={downloadDisabled}
                  onClick={() => {
                    this.getTicketPDF(order.order.orderToken);
                  }}
                >
                  {downloadDisabled ? (
                    <span>
                      {t('button.download_tickets')}
                      <span className="TicketLoader">
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </span>
                    </span>
                  ) : (
                    <span>
                      {t('button.download_tickets')}
                      <GetAppIcon className="AccountOrderButtonIcon" />
                    </span>
                  )}
                </Button>
              </Col>
            </Row>
            <div className="Block">
              <Row className="OrderItemHeader">
                <Col md={4}>
                  {t('account.table.ticket')}
                </Col>
                <Col md={5}>
                  {t('account.table.linked_to')}
                </Col>
                <Col md={3}>
                  &nbsp;
                </Col>
              </Row>

              {tickets && tickets.map((ticket) => (
                <TicketItem
                  key={ticket.token}
                  ticketName={ticket.name}
                  ticketToken={ticket.token}
                  orderToken={order.order.orderToken}
                  eventName={ticket.eventName}
                  isPersonalizable={ticket.isPersonalizable}
                  fromDate={ticket.validFrom}
                  tillDate={ticket.validTill}
                  attendee={ticket.attendee}
                />
              ))}
            </div>
          </Container>
        </div>

        <Footer />
      </div>
    );
  }
}

export default withTranslation()(AccountOrdersOrder);

AccountOrdersOrder.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderSlug: PropTypes.string,
    }).isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
};
