import React from 'react';

import './Cart.scss';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {useTranslation} from 'react-i18next';
import {LinkContainer} from 'react-router-bootstrap';
import {generatePath} from 'react-router-dom';
import Loader from 'components/Loader';
import CartItem from 'components/Cart/CartItem';
import {trackInitiateCheckOut} from 'services/tracking/TrackingHandler';
import MoneyString from '../MoneyString';
import {GetCartQueryParams, useGetCart} from "../../hooks/Cart/useGetCart";

type SmallCartProps = {
    eventSlug: string,
    eventToken: string,
    eventName?: string,
    track: boolean
}

export const SmallCart: React.FC<SmallCartProps> = ({eventSlug, eventToken, eventName, track}) => {
    const {
        data: data,
        isLoading: isCartLoading,
        isFetching: isCartFetching,
        isError: isCartError,
    } = useGetCart({eventToken} as GetCartQueryParams);

    const {t, i18n} = useTranslation();

    if (isCartError) return <div>{t('error.cart.get')}</div>;

    const products = data?.products ?? [];
    const totalPrice = data?.totalPrice;
    const employee = data?.employee ?? false;

    if (totalPrice && track) {
        trackInitiateCheckOut(totalPrice);
    }

    return (
        <div className="SmallCart">
            <div className="CartBody">
                <h2>
                    {t('cart.title')}
                    {eventName && ` ${t('cart.for')} ${eventName}`}
                </h2>
                {isCartFetching && <Loader className="CartLoader"/>}
                {products && products.length > 0
                    ? (
                        <div className={isCartFetching ? 'CartInner Loading' : 'CartInner'}>
                            <div className="CartContents">
                                {products.map((item) => (
                                    <CartItem
                                        key={`cartitem-${item.token}`}
                                        name={item.name}
                                        varietyName={item.varietyName}
                                        quantity={item.quantity}
                                        originalPrice={item.originalPrice}
                                        unitPrice={item.unitPrice}
                                        totalPrice={item.totalPrice}
                                        discounts={item.discounts}
                                        vouchers={item.vouchers}
                                    />
                                ))}
                            </div>
                            <Row className="CartTotal">
                                <Col className="CartTotalText" xs={8}>
                                    <div className="CartTotalTextTotal">
                                        {t('cart.total')}
                                    </div>
                                    <div className="CartTotalTextDescription">
                                        {t('cart.includingVAT')}
                                    </div>
                                </Col>
                                <Col className="CartTotalAmount" xs={4}>
                                    <MoneyString
                                        amount={totalPrice.amount}
                                        currency={totalPrice.currency}
                                    />
                                </Col>
                            </Row>
                        </div>
                    ) : (
                        <div className="CartContents">
                            {!isCartLoading && (
                                <div className="CartEmpty">
                                    {t('cart.cart_is_empty')}
                                </div>
                            )}
                        </div>
                    )}

                {employee === false && (
                    <div className="SmallCartButtons">
                        <LinkContainer
                            to={`/${i18n.language}${generatePath(t('routes:ticket.cart'),
                                {eventSlug})}`}
                        >
                            <Button className="BackLink" variant="link">
                                <ChevronLeftIcon/>
                                {' '}
                                {t('button.edit_cart')}
                            </Button>
                        </LinkContainer>
                    </div>
                )}
            </div>
        </div>
    );
}

export default SmallCart;