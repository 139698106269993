import React, {ReactNode} from 'react';
import {Switch} from 'react-router';
import {useTranslation} from 'react-i18next';

/**
 *
 * @param path can be string, undefined or string array
 * @param i18n
 * @param t
 * @returns Localized string path or path array
 */
export function localizeRoutePath(path: string | string[] | undefined, i18n: any, t: any): string | undefined {
  const locale = i18n.language;

  switch (typeof path) {
    case 'undefined':
      return undefined;
    default:
      return path === '*' ? path : `/${locale}${t(path)}`;
  }
}

interface LocalizedSwitchProps {
  children: ReactNode;
}

const LocalizedSwitch: React.FC<LocalizedSwitchProps> = ({ children }) => {
  /**
   * Inject params and formatMessage through hooks, so we can localize the route
   */
  const { t, i18n } = useTranslation('routes');

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
      <Switch>
        {React.Children.map(children, (child) => {
          return React.isValidElement(child)
              ? React.cloneElement(child, {
                ...child.props,
                path: localizeRoutePath(child.props.path, i18n, t),
              })
              : child;
        })}
      </Switch>
  );
};

export default LocalizedSwitch;
