import {useQuery} from "@tanstack/react-query";
import {SingleEvent} from "../../types/SingleEvent.type";
import {handleInternalApiCall, METHOD_GET} from "../../services/api-requests/ApiCallHandler";
import {SingleOrder} from "../../types/SingleOrder.type";

export type GetOrderQueryParams = {
    orderSlug: string;
}

const QUERY_KEY = 'order';

const fetchEvent = async (params: GetOrderQueryParams): Promise<{
    event: SingleEvent,
    order: SingleOrder
}> => {
    const response = await handleInternalApiCall(
        `shop/order/${params.orderSlug}`,
        METHOD_GET,
        false,
        null
    );

    return response.data;
};

export const useGetOrder = (params: GetOrderQueryParams) => {
    return useQuery<{
        event: SingleEvent,
        order: SingleOrder
    }, Error>(useGetOrderQuery(params));
};

export const useGetOrderQuery = (params: GetOrderQueryParams) => {
    return {
        queryKey: [QUERY_KEY + params.orderSlug],
        queryFn: () => fetchEvent(params),
        staleTime: 0,
    }
};