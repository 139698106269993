import React, {FC, Fragment} from 'react';
import {ErrorMessage, FieldInputProps} from 'formik';
import Form from "react-bootstrap/Form";

const FieldErrors: FC<{ field: FieldInputProps<any>; serverErrors?: any; }> = ({
                                                                                   field,
                                                                                   serverErrors,
                                                                               }) => {
    return (
        <>
            {/* @ts-ignore */}
            <ErrorMessage
                name={field.name}
                render={msg => {
                    if (typeof msg === 'object') {
                        return <Form.Text className="InputError">
                            {
                                Object.values(msg).map((message: string, index: number) => (
                                    <div key={index} className="SingleInputError">
                                        {message}
                                    </div>))
                            }
                        </Form.Text>;
                    } else {
                        return (
                            <Form.Text className="InputError">
                                {msg}
                            </Form.Text>
                        );
                    }
                }}
            />

            {serverErrors && serverErrors[field.name] && (
                <Form.Text className="InputError">
                    {serverErrors[field.name].map((errorText, index) => (
                        <div key={index} className="SingleInputError">
                            {errorText}
                        </div>
                    ))}
                </Form.Text>
            )}
        </>
    );
};

export default FieldErrors;