import {useQueryClient} from '@tanstack/react-query';
import {toast} from 'react-toastify';
import {handleInternalApiCall, METHOD_POST} from 'services/api-requests/ApiCallHandler';
import LocalStorageService from 'services/storage/LocalStorageService';
import {logError} from 'services/error-handling/ErrorHandler';
import ToastContent from 'components/ToastContent';
import React from "react";
import {QUERY_KEY} from "./useGetCart";
import {FormikValues} from "formik";

interface UseChangeCartItemProps {
    eventToken: string;
    t: any;
}

export const useApplyVoucher = ({
                                    eventToken,
                                    t,
                                }: UseChangeCartItemProps) => {
    const queryClient = useQueryClient();

    const applyVoucher = async (values: FormikValues, {setSubmitting}, onSuccess: () => void) => {
        setSubmitting(true);

        try {
            await handleInternalApiCall(
                `shop/cart/${LocalStorageService.getCartUuid()}/event/${eventToken}/voucher/add/${values.voucherCode}`,
                METHOD_POST,
                false,
                null
            );
            setSubmitting(false);
            queryClient.invalidateQueries({queryKey: [QUERY_KEY + eventToken]});
            onSuccess();
        } catch (error) {
            logError(error);
            setSubmitting(false);

            if (error.response && typeof error.response.data.error !== 'undefined') {
                toast.error(<ToastContent type="error" code={error.response.data.error}/>);
            } else {
                logError(error);
                toast.error(<ToastContent type="error" message={t('error.voucher.post')}/>, {
                    position: 'top-center',
                    autoClose: false,
                });
            }
        }
    };

    return {applyVoucher};
};