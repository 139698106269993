import React from 'react';
import Form from 'react-bootstrap/Form';
import MaskedInput from 'react-text-mask';
import Select, {ActionMeta, OnChangeValue, Props as SelectProps} from 'react-select';
import {FieldProps} from "formik";
import FieldErrors from "./FieldErrors";

type IsMulti = boolean;
type DefaultOptionType = { label: string; value: string };

type InputProps = FieldProps & SelectProps & {
    mask: MaskedInput.mask,
    serverError?: string[],
    type?: string,
    onClick?: (event) => void,
    onChange?: (event) => void,
}

type MultiValue<DefaultOptionType> = readonly DefaultOptionType[];
type SingleValue<DefaultOptionType> = DefaultOptionType;

export function isMultipleOption<DefaultOptionType>(
    arg: MultiValue<DefaultOptionType> | SingleValue<DefaultOptionType>
): arg is MultiValue<DefaultOptionType> {
    return Array.isArray(arg);
}

const Dropdown: React.FC<InputProps> = ({
                                         field,
                                         form,
                                         options,
                                         placeholder,
                                         serverError,
                                         isOptionDisabled,
                                         menuPosition,
                                         onClick,
                                         onChange,
                                     }) => {

    if(field === undefined) {
        return null;
    }

    return (
        <Form.Group className="Select" onClick={onClick}>
            <Select
                options={options}
                placeholder={placeholder}
                onChange={(
                    value: OnChangeValue<DefaultOptionType, IsMulti>,
                    action: ActionMeta<DefaultOptionType>
                ) => {
                    if(onChange) {
                        onChange(value);
                    }

                    const returnValue = value && (isMultipleOption(value) ? value.map(rawValue => rawValue.value) : value.value);

                    field.onChange({ target: { name: field.name, value: returnValue } });
                }}
                onBlur={field.onBlur}
                value={options && options.find((option: DefaultOptionType) => field.value === option.value)}
                name={field.name}
                isOptionDisabled={isOptionDisabled}
                menuPosition={menuPosition}
            />

            <FieldErrors field={field} serverErrors={serverError} />
        </Form.Group>
    );
}

export default Dropdown;