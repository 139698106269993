import React, {useState} from 'react';

import './Cart.scss';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {useTranslation} from 'react-i18next';
import VerifiedIcon from '@material-ui/icons/VerifiedUser';
import VoucherIcon from '@material-ui/icons/CardGiftcard';
import {LinkContainer} from 'react-router-bootstrap';
import {generatePath} from 'react-router-dom';
import queryString from 'query-string';
import VoucherForm from 'components/Form/VoucherForm';
import Loader from 'components/Loader';
import CartItem from 'components/Cart/CartItem';
import MoneyString from '../MoneyString';
import {GetCartQueryParams, useGetCart} from "../../hooks/Cart/useGetCart";

type CartProps = {
    image: string,
    eventSlug: string,
    eventToken: string,
}

export const Cart: React.FC<CartProps> = ({image, eventSlug, eventToken}) => {
    const {
        data: data,
        isLoading: isCartLoading,
        isFetching: isCartFetching,
        isError: isCartError
    } = useGetCart({eventToken} as GetCartQueryParams);

    const {t, i18n} = useTranslation();

    const products = data?.products ?? [];
    const totalPrice = data?.totalPrice;

    const urlParams = queryString.parse(window.location.search);

    const [showVoucherInput, setShowVoucherInput] = useState(typeof urlParams.voucher !== 'undefined');

    if (isCartError) return <div>{t('error.cart.get')}</div>;

    return (
        <Container className="CartWrapper SideCart">
            <div className="CartFill"/>
            <div className="Cart">
                <div className="CartHeader">
                    <img src={image} alt=""/>
                    <h2>{t('cart.title')}</h2>
                </div>
                <div className="CartBody">
                    {isCartFetching && <Loader className="CartLoader"/>}
                    <>
                        {products && products.length > 0
                            ? (
                                <div className={isCartFetching ? 'CartInner Loading' : 'CartInner'}>
                                    <div className="CartContents">
                                        {products.map((item) => (
                                            <CartItem
                                                key={`cartitem-${item.token}`}
                                                name={item.name}
                                                varietyName={item.varietyName}
                                                quantity={item.quantity}
                                                originalPrice={item.originalPrice}
                                                unitPrice={item.unitPrice}
                                                totalPrice={item.totalPrice}
                                                discounts={item.discounts}
                                                vouchers={item.vouchers}
                                            />
                                        ))}
                                    </div>
                                </div>
                            ) : (
                                <div className="CartContents">
                                    {!isCartLoading && (
                                        <div className="CartEmpty">
                                            {t('cart.cart_is_empty')}
                                        </div>
                                    )}
                                </div>
                            )}
                    </>
                </div>
                <div className={isCartFetching ? 'CartFooter Loading' : 'CartFooter'}>
                    {products && products.length > 0 && (
                        <Row className="CartTotal">
                            <Col className="CartTotalText" xs={8}>
                                <div className="CartTotalTextTotal">
                                    {t('cart.total')}
                                </div>
                                <div className="CartTotalTextDescription">
                                    {t('cart.includingVAT')}
                                </div>
                            </Col>
                            <Col className="CartTotalAmount" xs={4}>
                                <MoneyString
                                    amount={totalPrice.amount}
                                    currency={totalPrice.currency}
                                />
                            </Col>
                        </Row>
                    )}
                    {(!showVoucherInput) && (
                        <div className="CartVoucherAdd">
                            <Button className="AddVoucherLink" variant="link" onClick={() => setShowVoucherInput(true)}>
                                <VoucherIcon/>
                                {' '}
                                {t('cart.addVoucher')}
                            </Button>
                        </div>
                    )}
                    {showVoucherInput && (
                        <VoucherForm
                            eventToken={eventToken}
                            toggleVoucherClick={() => setShowVoucherInput(false)}
                        />
                    )}
                    <div className={isCartLoading ? 'CartButtons Loading' : 'CartButtons'}>
                        <LinkContainer
                            to={`/${i18n.language}${generatePath(t('routes:ticket.cart'),
                                {eventSlug})}`}
                        >
                            <Button
                                variant="primary"
                                block
                                size="lg"
                                disabled={products.length === 0}
                            >
                                {t('event.button.buy_tickets')}
                            </Button>
                        </LinkContainer>
                    </div>

                    <div className="SafePayment">
                        <VerifiedIcon className="SafePaymentIcon"/>
                        {' '}
                        {t('cart.safePayment')}
                    </div>
                </div>
            </div>
        </Container>
    );
}

export default Cart;