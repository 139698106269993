import {RouteComponentProps} from "react-router";
import {useTranslation} from "react-i18next";
import ErrorPage from "../Error/ErrorPage";
import PageNotFound from "../Error/PageNotFound";
import React from "react";
import {GetOrderQueryParams, useGetOrder} from "../../hooks/Order/useGetOrder";
import FullScreenLoader from 'components/Loader/FullScreenLoader';
import {generatePath, Redirect} from "react-router-dom";

interface OrderProps extends RouteComponentProps<{
    orderSlug: string;
}> {
}

export const Order: React.FC<OrderProps> = ({match}) => {

    const {t, i18n} = useTranslation();

    const {orderSlug} = match.params;

    const {
        data: data,
        isLoading: isOrderLoading,
        isFetching: isOrderFetching,
        isError: isOrderError
    } = useGetOrder({orderSlug} as GetOrderQueryParams);

    if (isOrderFetching) return <FullScreenLoader route={match}/>;
    if (isOrderError) return <ErrorPage title={t('error.title')} text={t('error.event')}/>;
    if (data === null) return <PageNotFound />;

    if (data === undefined) {
        return <div></div>;
    }

    const { event, order } = data;

    if(order.status === 'paid' || order.status === 'completed' || order.status === 'shipping') {
        // Paid order
        return <Redirect
            to={`/${i18n.language}${generatePath(t('routes:ticket.order_confirmed'), {
                orderSlug: orderSlug,
            })}`}
        />
    } else if(order.status === 'confirmed' || order.status === 'awaiting_payment' || order.status === 'open') {
        // Open order
        return <Redirect
            to={`/${i18n.language}${generatePath(t('routes:ticket.order_pay'), {
                orderSlug: orderSlug,
            })}`}
        />
    } else if(order.status === 'expired') {
        return <Redirect
            to={`/${i18n.language}${generatePath(t('routes:ticket.order_expired'), {
                orderSlug: orderSlug,
            })}`}
        />
    } else {
        return <PageNotFound/>;
    }
}